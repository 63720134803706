import { PopupModal } from "react-calendly";
import React, { useState } from "react";
import "./herosection.css";
import logo from "../../images/hero.gif";
import { Mixpanel } from "../../mixpanel/mixpanel";
const HeroSection = () => {
  const bookTrialClicked = () => {
    Mixpanel.track("Book Trial Clicked - Hero Section");
    setCalendlyOpen(true);
  };
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  return (
    <div className="hero-section">
      <div className="hero-section-wrapper">
        <div className="flex-col">
          <h1 className="hero-heading">
            <span className="hero-span-1">
              Financial Planning to build your{" "}
            </span>
            <span className="hero-span-2">Wealth...</span>
          </h1>
          <p className="hero-subtext">
            We have a secret and it's simple. We can help you get started with a
            financial plan or improve your existing one. Clients like you trust
            us because we're independent, unbiased and affordable.
          </p>
          <button
            className="cta-primary"
            id="hero-section-book-session"
            onClick={() => bookTrialClicked()}
          >
            <h2 className="cta-text">Book a FREE trial</h2>
          </button>

          <PopupModal
            url="https://calendly.com/bridgit-financial-advisors/financial-planning"
            //   pageSettings={this.props.pageSettings}
            //   utm={this.props.utm}
            //   prefill={this.props.prefill}
            onModalClose={() => setCalendlyOpen(false)}
            open={calendlyOpen}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
          />
        </div>
        <div className="image-div">
          <img
            className="hero-image"
            src={logo}
            alt="bridgit-financial-planning-service"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
