import React from "react";
import "./howwebridge.css";
import ScrollAnimation from "react-animate-on-scroll";

function HowWeBridgeSection({
  titleshow,
  imgStart,
  heading,
  description,
  img,
}) {
  return (
    <>
      <div className="how-webridge-section">
        <div className="how-webridge-wrapper">
          <ScrollAnimation
            animateIn="fadeIn"
            className={`switch ${
              titleshow ? "how-webridge-title" : "how-webridge-title-noshow"
            }`}
          >
            <h2 className="how-webridge-title">
              How we <span className="how-webridge-span">bridge</span> you to
              your goals?
            </h2>
          </ScrollAnimation>
          <div
            className={`switch ${
              imgStart
                ? "how-webridge-row-col2col1"
                : "how-webridge-row-col1col2"
            }`}
          >
            <div className="how-webridge-col">
              <div className="how-webridge-textwrapper">
                <h3 className="how-webridge-h1">{heading}</h3>
                <h4 className="how-webridge-subtitle">{description}</h4>
              </div>
            </div>
            <div className="how-webridge-col2">
              <div className="how-webridge-imagewrap">
                <img
                  className="how-webridge-image"
                  src={img}
                  alt="bridgit-financial-plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowWeBridgeSection;
