export const getStarted = {
  heading: "1. Get Started With Bridgit's Financial Plan!",
  description:
    "Our first session will be about knowing your finances, based on which we will curate a financial plan covering savings, investments, goal planning, insurance, and taxes.",
  imgStart: true,
  img: require("../../images/get-financial-plan.gif"),
  alt: "get-bridgit-financial-plan",
  titleshow: true,
};

export const quarterlyReview = {
  heading: "2. Quarterly Reviews",
  description:
    "Bridgit advisors actively study Indian and global markets. Based on our research, we examine your investments and rebalance your portfolio quarterly to keep you on track to achieve your financial goals.",
  imgStart: false,
  img: require("../../images/quarterly-review.gif"),
  alt: "bridgit-quarterly-review",
  titleshow: false,
};

export const advisorySession = {
  heading: "3. Sessions with your advisor whenever you need",
  description:
    "Good advice takes time. And you will have our undivided attention for 30 mins whenever you need it.",
  imgStart: true,
  img: require("../../images/session-with-advisor.gif"),
  alt: "Bridgit-Session-with-advisor",
  titleshow: false,
};

export const personalizedHotline = {
  heading: "4. Bridgit Hotline",
  description:
    "For all the little questions that you would have, your bridgit advisors are just a WhatsApp text away :)",
  imgStart: false,
  img: require("../../images/personalized-hotline.gif"),
  alt: "bridgit-personalized-hotline",
  titleshow: false,
};
