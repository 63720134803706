import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { PopupModal } from "react-calendly";
import logo from "../../images/logo-Bridgit.svg";
import config from "../../shared/config";

import "./navelements.css";
import { Mixpanel } from "../../mixpanel/mixpanel";
const NavBar = () => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const bookTrialClickNav = (mode) => {
    Mixpanel.track("Book Trial Clicked " + mode + " - Nav");
    setCalendlyOpen(true);
  };
  const whatsappClickNav = (mode) => {
    Mixpanel.track("Whatsapp Clicked " + mode + "- Nav");
    openInNewTab("https://wa.me/919319096509");
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onLinkClick = (url) => {
    window.open(url, "_self", "noopener,noreferrer");
  };

  return (
    <div className="nav-section">
      <div className="nav-section-container">
        <img
          className="nav-company-logo"
          src={logo}
          alt={"bridgit-logo"}
          onClick={() =>
            onLinkClick(config.get(process.env.NODE_ENV)["WEB_URL"])
          }
        ></img>
        <div className="nav-items-container">
          <div className="nav-links-container">
            <h2
              className="nav-links"
              id="pricing-click"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/pricing"
                )
              }
            >
              Pricing
            </h2>
            <h2
              className="nav-links"
              id="testimonial-click"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "#testimonial"
                )
              }
            >
              Testimonials
            </h2>
            <h2
              className="nav-links"
              id="blogs-click"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/blogs"
                )
              }
            >
              Blogs
            </h2>
          </div>
          <button
            className="nav-button-book-session"
            onClick={() => bookTrialClickNav("web")}
            id="nav-button-book-sesssion"
          >
            {" "}
            FREE Trial
          </button>
          <PopupModal
            url="https://calendly.com/bridgit-financial-advisors/financial-planning"
            //   pageSettings={this.props.pageSettings}
            //   utm={this.props.utm}
            //   prefill={this.props.prefill}
            onModalClose={() => setCalendlyOpen(false)}
            open={calendlyOpen}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
          />
          <button
            className="nav-buton-whatsapp"
            onClick={() => whatsappClickNav("web")}
            id="nav-button-whatsapp"
          >
            <FaWhatsapp color="white" fontSize="1.8em" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
