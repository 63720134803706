import HomePage from "./pages/homePage";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalDebug } from "./GlobalDebug";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import TermsOfService from "../src/components/T&C";
import PrivacyPolicy from "./components/PrivacyPolicy";
import BlogsSection from "./components/BlogsSection/BlogsSection";
import Blogs from "./components/BlogsSection/Blogs/Blogs";
import PricingPage from "./components/PricingPage";
const App = () => {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/blogs" element={<BlogsSection />} />
        <Route path="/blogs/:blogSlug" element={<Blogs />} />
        <Route path="/pricing/:pricingType" element={<PricingPage />} />
        <Route path="/pricing/" element={<PricingPage />} />
      </Routes>
    </Router>
  );
};

export default App;
