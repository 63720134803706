import React, { useEffect, useState } from "react";
import FooterSection from "../Footer";
import NavBar from "../NavBar";
import BlogItem from "./BlogItem";
import "./blogsSection.css";
import BlogTheme from "./BlogTheme";
import { useNavigate } from "react-router-dom";
import { getAllBlogs } from "../../sevice/getBlogs";
const BlogsSection = () => {
  const [themeMap, setThemeMap] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [originalTagAndBlogList, setOriginalTagAndBlogList] = useState(
    new Map()
  );
  const navigate = useNavigate();
  const openBlogPage = (blogSlug) => {
    navigate("/blogs/" + blogSlug);
  };
  const selectTheme = (theme, index) => {
    let filteredArray = Array.from(originalTagAndBlogList.get(theme.theme));
    filteredArray.sort(sortByDate);

    setThemeMap(
      themeMap.map((theme, i) => {
        if (i === index) {
          theme.isSelected = !theme.isSelected;
        } else {
          theme.isSelected = false;
        }

        return theme;
      })
    );
    setBlogs(filteredArray);
  };

  const showAllBlogs = () => {
    getBlogs();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs();
  }, []);

  const getBlogs = () => {
    getAllBlogs().then((blogDetails) => {
      let themes = new Set();
      let blogs = [];
      let tagAndBlogMap = new Map([]);
      let themeMap = [{ theme: "All Blogs", isSelected: true }];
      for (let blogDetail of blogDetails.blogDetailsList) {
        blogs.push(blogDetail);
        for (let theme of blogDetail.blogTags) {
          themes.add(theme);
          if (tagAndBlogMap.has(theme)) {
            let newArray = Array.from(tagAndBlogMap.get(theme));
            newArray.push(blogDetail);
            tagAndBlogMap.set(theme, newArray);
          } else {
            tagAndBlogMap.set(theme, new Array(blogDetail));
          }
        }
      }
      for (let theme of Array.from(themes)) {
        themeMap.push({ theme: theme, isSelected: false });
      }

      blogs.sort(sortByDate);
      setThemeMap(themeMap);
      setBlogs(blogs);
      setOriginalTagAndBlogList(tagAndBlogMap);
    });
  };

  const sortByDate = (a, b) => {
    if (a.blogPublishDate < b.blogPublishDate) {
      return -1;
    }
    if (a.blogPublishDate > b.blogPublishDate) {
      return 1;
    }
    return 0;
  };
  return (
    <>
      <NavBar />
      <div className="blogSection-page">
        <div className="blogSection-page-container">
          <div className="blog-heading">
            <h2 className="h2-blog">Blogs</h2>
          </div>
          <div className="blog-themes">
            <h3 className="theme-heading">Popular themes</h3>
            <div class="blog-theme-container">
              {themeMap.map((theme, index) => {
                if (theme.theme === "All Blogs") {
                  return (
                    <BlogTheme
                      blogTheme={theme.theme}
                      isSelected={theme.isSelected}
                      onClick={() => showAllBlogs()}
                    />
                  );
                } else {
                  return (
                    <BlogTheme
                      blogTheme={theme.theme}
                      isSelected={theme.isSelected}
                      onClick={() => selectTheme(theme, index)}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="blog-items-container">
            {blogs.map((blog, index) => {
              return (
                <BlogItem
                  key={index}
                  blogTitle={blog.blogTitle}
                  blogPublishDate={blog.blogPublishDate}
                  imageLink={blog.imageLink}
                  onClick={() => openBlogPage(blog.blogSlug, blog)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default BlogsSection;
