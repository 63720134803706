import React, { useEffect, useState } from "react";
import FooterSection from "../../Footer";
import NavBar from "../../NavBar";
import "./blogs.css";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import BlogTheme from "../BlogTheme";
import logo from "../../../images/logo-round.png";
import backArrow from "../../../images/back-arrow.svg";
import { useParams } from "react-router-dom";

import RelatedArticles from "./RelatedArticles";
import { getBlog } from "../../../sevice/getBlogs";
const Blogs = () => {
  const { blogSlug } = useParams();
  const [relatedArticle, setrelatedArticle] = useState([]);
  const [blog, setBlog] = useState();
  const [pageReady, setpageReady] = useState(false);
  useEffect(() => {
    getBlog(blogSlug).then((response) => {
      if (response.blog != null) {
        setrelatedArticle(response.relatedArticles);
        setpageReady(true);
        setBlog(response.blog);
      }
    });
  }, []);

  const navigate = useNavigate();
  const goToBlogs = () => {
    navigate("/blogs", {});
  };

  return (
    <>
      <NavBar />
      {pageReady ? (
        <div className="blogPage-page">
          <div className="blogPage-section">
            <div className="blogPage-margin"></div>
            <div className="blogPage-container">
              <div className="blog-path">
                <div
                  className="blog-path-common"
                  onClick={() => {
                    goToBlogs();
                  }}
                >
                  <img src={backArrow} alt={"back arrow"} /> All Blogs &ensp;
                </div>
              </div>
              <div className="blog-content">
                <h1 className="blog-content-heading">{blog.blogTitle}</h1>
                <div className="blog-content-authorInfo">
                  <img
                    className="blog-content-profile"
                    src={logo}
                    alt="team-bridgit"
                  ></img>
                  <h3 className="blog-content-name"> by Team Bridgit,</h3>
                  <div className="blog-content-date">
                    {blog.blogPublishDate}
                  </div>
                </div>
                <img
                  className="blog-content-image"
                  src={blog.imageLink}
                  alt={blog.blogTitle}
                ></img>
                <div className="blog-content-para">
                  <ReactMarkdown>{blog.blogContent}</ReactMarkdown>
                </div>
                <div className="blog-themes">
                  {Array.from(blog.blogTags).map((theme) => {
                    return <BlogTheme blogTheme={theme} />;
                  })}
                </div>
              </div>
              <div className="blog-footer">
                <img
                  className="blog-footer-profilePic"
                  src={logo}
                  alt="team-bridgit"
                ></img>
                <div className="blog-footer-profileInfo">
                  <h4 className="blog-footer-writtenBy">Written By</h4>
                  <h4 className="blog-footer-name">Team Bridgit</h4>
                  <h4 className="blog-footer-date">{blog.blogPublishDate}</h4>
                </div>
              </div>
            </div>
            <div className="blogPage-relatedArticles">
              <div className="blog-relatedArticlesList">
                <h4 className="blog-relatedArticleHeading">Related Articles</h4>
                <li className="relatedBlogsList">
                  {relatedArticle.map((article) => {
                    return (
                      <RelatedArticles
                        blogPublishDate={article.blogPublishDate}
                        blogTitle={article.blogTitle}
                        Imagesrc={article.imageLink}
                        blogSlug={article.blogSlug}
                      />
                    );
                  })}
                </li>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <FooterSection />
    </>
  );
};

export default Blogs;
