import axios from "axios";

export const getAllBlogs = async () => {
  // Construct Request URL
  // Construct reequest data

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   };
  // Making request
  try {
    const response = await axios.get(
      "https://rdnai77e7j.execute-api.us-east-1.amazonaws.com/Prod/blogs/getAllBlogs"
    );
    if (response.data !== undefined) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBlog = async (blogSlug) => {
  // Construct Request URL
  // Construct reequest data

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const data = {
    blogSlug: blogSlug,
  };
  // Making request
  try {
    const response = await axios.post(
      "https://rdnai77e7j.execute-api.us-east-1.amazonaws.com/Prod/blogs/getBlog",
      data,
      {
        headers: headers,
      }
    );
    if (response.data !== undefined) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
