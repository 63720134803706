import React from "react";

function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={
        "faq " +
        (faq.open ? "open" : "") +
        (faq.open ? " border-open" : " border-closed")
      }
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <h4 className="faq-question">{faq.question}</h4>
      <h6 className="faq-answer">{faq.answer}</h6>
    </div>
  );
}

export default FAQ;
