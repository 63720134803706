import React, { useState, useEffect } from "react";
import "./pricing.css";
import NavBar from "../NavBar";
import { PopupModal } from "react-calendly";
import TaxPricing from "./taxpricing";
import FPSPricing from "./fpspricing";
import ComboPricing from "./combopricing";
import { useParams } from "react-router-dom";
const PricingPage = () => {
  const { pricingType } = useParams();
  useEffect(() => {
    console.log(pricingType);
    if (pricingType === "fps") {
      showFPSPricing();
    } else if (pricingType === "taxfiling") {
      showITRPricing();
    } else if (pricingType === "combo") {
      showComboPricing();
    } else {
      showFPSPricing();
    }
  }, []);
  const showITRPricing = () => {
    setITRPricing(true);
    setFPSPricing(false);
    setComboPricing(false);
  };
  const showFPSPricing = () => {
    setITRPricing(false);
    setFPSPricing(true);
    setComboPricing(false);
  };
  const showComboPricing = () => {
    setITRPricing(false);
    setFPSPricing(false);
    setComboPricing(true);
  };
  const [itrPricing, setITRPricing] = useState(false);
  const [fpsPricing, setFPSPricing] = useState(false);
  const [comboPricing, setComboPricing] = useState(false);
  const ITRFilingCalendly =
    "https://calendly.com/bridgit-financial-advisors/itr-filing";
  return (
    <>
      <NavBar />
      <div className="pricing-page">
        <div className="pricing-page-info-container">
          <div className="pricing-page-header-container">
            <div className="pricing-page-header">Pricing</div>
          </div>
          <div className="pricing-page-container">
            <div className="pricing-page-tabs">
              <div className="pricing-page-tabs-container">
                <div
                  className={
                    "pricing-page-tab-heading" + (itrPricing ? "-selected" : "")
                  }
                  onClick={() => showITRPricing()}
                >
                  Tax Filing
                  <div className="new-banner">New</div>
                </div>
                <div
                  className={
                    "pricing-page-tab-heading" + (fpsPricing ? "-selected" : "")
                  }
                  onClick={() => showFPSPricing()}
                >
                  Financial Planning
                </div>
                <div
                  className={
                    "pricing-page-tab-heading" +
                    (comboPricing ? "-selected" : "")
                  }
                  onClick={() => showComboPricing()}
                >
                  Packages
                  <div className="recommended-banner">Recommended</div>
                </div>
              </div>
            </div>
            {itrPricing ? <TaxPricing /> : null}
            {fpsPricing ? <FPSPricing /> : null}
            {comboPricing ? <ComboPricing /> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
