import React from "react";
import "./privacy.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo-Bridgit.svg";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-section">
      <div className="privacy-policy-wrapper">
        <div className="tos-logo">
          <Link to="/">
            <img src={logo} style={{ width: "250px" }} />
          </Link>
        </div>
        <div className="privacy-heading">Privacy Policy</div>
        <div className="privacy-subheading">Last Updated: 01 November 2022</div>
        <div className="privacy-container">
          <div className="privacy-subbtext-heading">INTRODUCTION</div>
          <div className="privacy-subtext-paragraph">
            Bridgit Financial Advisors Private Limited ("Bridgit" , "we" , "us"
            , "our" or "Company") is a fintech company providing financial
            solutions and services to users through our Platform, as described
            in our terms of service (“Terms of Service”).
            <br></br>
            <br></br>
            We at Bridgit are committed to protecting the privacy and security
            of its users (“user(s)”, “your”, or “you”).
            <br></br>
            <br></br>
            Your privacy is important and maintaining your trust is paramount to
            us. This Privacy Policy (“Privacy Policy”) explains how we collect,
            use, process, disclose, and safeguard your information when you use
            our website and mobile app, respectively (collectively referred to
            as the “Platform”) and our Services (defined below) through our
            Platform, which shall be incorporated into and subject to the Terms
            of Service of the Platform and shall be read harmoniously and in
            conjunction.
            <br></br>
            <br></br>
            The Platform and related Services are primarily available for use
            and download within India only. We strictly adhere to the
            requirements of the data protection law established in India and
            this Privacy Policy is published in accordance with:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                Section 43A of the Information Technology Act, 2000 (“IT Act”);
              </li>
              <li style={{ marginTop: "1%" }}>
                Rule 4 of the Information Technology (Reasonable Security
                Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011 (the “SDPI Rules”); and
              </li>
              <li style={{ marginTop: "1%" }}>
                Rule 3 of the Information Technology (Intermediary Guidelines)
                Rules, 2011 (as may be amended).
              </li>
            </ol>
            Please read this Privacy Policy carefully to understand our policies
            and practices regarding your information and how we will treat it.
            <br></br>
            <br></br>
            The Platform caters to individual end-users for their personal use
            only and not for commercial purposes. The Platform allows you to
            build your own financial plan (retirement planning, investment
            planning and goal planning among other categories) purely based on
            the information provided by you on the Platform. You may utilise the
            functionalities made available by us to build your plan. We use a
            combination of cutting-edge technology and common sense, that guides
            you at every step of your journey, during and even after your
            lifetime. All results generated by the Platform is made available on
            as-is and available basis based on the information you provide us
            (“Services”). By accessing or using our Platform and our Services,
            you agree to accept all the terms contained in this Privacy Policy
            and acknowledge and agree with the practices described herein. If
            you do not agree with the terms of this Privacy Policy, please do
            not access and use, our Platform and our Services.
            <br></br>
            <br></br>
            This Privacy Policy describes our privacy practices for all
            websites, products and services that are linked to it. However, this
            Privacy Policy does not apply to those affiliates and partners that
            have their own privacy policy. In such situations, we recommend that
            you read the privacy policy on the applicable website. Any
            capitalised terms not defined hereunder shall hold the same
            definition as provided under the Terms of Service.
            <br></br>
            <br></br>
            You may sign-up and login by connecting your account with certain
            third-party networks like Facebook or Gmail, for example (each a
            “Linked Network”). If you elect to sign up through or connect a
            Linked Network, we receive certain profile and account information
            about you from the Linked Network. The specific information provided
            by Linked Networks is determined by you and these third parties and
            may vary by network. In all cases, the permissions page for the
            Linked Network will describe the information being shared. You
            should consult their respective privacy policies for information
            about their practices.
            <br></br>
            <br></br>
            If you have any questions regarding this Privacy Policy, please send
            us an email at support@bridgit.club
          </div>
          <div className="privacy-subbtext-heading">
            WHAT INFORMATION DO WE COLLECT?
          </div>
          <div className="privacy-subtext-paragraph">
            We collect Personal Information (as defined below) from you when you
            register or set up account with us on our Platform. When you start
            using the Services, we ask you to provide certain information as
            part of the registration process, and in the course of your
            interface with the Platform. We will collect this information
            through multiple means and in various places through the Services,
            including but not limited to, account registration forms, contact us
            forms, Consolidated Account Statement (CAS) or any other file upload
            to our Platform when you interact with Bridgit. We will retrieve
            your financial information, as may be required by us and permitted
            by RBI from time to time, through the CAMS financial information
            aggregation functionality, only upon consent provided by you.
            <br></br>
            This Privacy Policy applies to:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                Information that (i) you may provide to us voluntarily while
                registering on our Platform for using our Services, and (ii) we
                are retrieving from third-party government agencies, including
                but not limited to your, mobile number, email address, password,
                date of birth, gender, Permanent Account Number (PAN), Aadhar
                details, e-KYC through UIDAI, CKYC through CERSAI, credit score
                and liabilities information from credit aggregation portals such
                as CRIF, income/salary information, risk profile, financial and
                investment advice (written as well as oral), marital status,
                sexual orientation, biometric data, health status and nominee
                details. We may also ask you to provide your financial
                information, including but not limited to, billing address, bank
                account details, credit/debit card details, payment related
                details, payment method data, debit instructions or other
                standing instructions to process payments for the Services
                (“Personal Information”). You can, if required, upload your CAMS
                or Karvy’s account statement to our Platform to populate, and
                track your investment history and portfolio details.
              </li>
              <li style={{ marginTop: "1%" }}>
                Certain additional information about yourself on a case-to-case
                basis. Further, when you visit our Platform, we may log certain
                non-personal information such as your IP address, cookie
                information, browser type, mobile operating system, manufacturer
                and model of your mobile device, geolocation, preferred
                language, access time and time spent. We will also collect
                information about the pages you view within our sites and other
                actions you take while using Platform (“Non-Personal
                Information”). We receive and store Non-Personal Information, by
                the use of the data collection devices such as ‘cookies’ on
                certain pages of the Platform, in order to help and analyse our
                webpage flow, track user trends, measure promotional
                effectiveness, demographic studies and promote trust and safety.
                This Non-Personal Information is compiled and analysed by the
                Company on an aggregated basis and not individually, in a manner
                that does not specifically identify you.
              </li>
              <li style={{ marginTop: "1%" }}>
                Any information that is freely available, accessible in the
                public domain, furnished under the Right to Information Act,
                2005 or any other law for the time being in forced, where such
                information shall not be regarded as sensitive personal data or
                information. We may also retrieve from your records available
                with third party Know Your Customer (KYC) Registration Agency
                (KRA), information such as your KYC details, KYC status,
                father’s name, occupation, address details and related
                documents.
              </li>
              <li style={{ marginTop: "1%" }}>
                All such Personal Information and Non-Personal Information
                described above, which shall be deemed to be disclosed willingly
                and without any coercion. No liability of any form pertaining to
                the authenticity/genuineness/misrepresentation/fraud/negligence,
                etc. of the Personal Information provided by you, shall lie on
                the Company, nor will the Company be in any way responsible to
                verify any information obtained from you.
              </li>
              <li style={{ marginTop: "1%" }}>
                The Company’s use of information received from third-party APIs
                will adhere to its respective data policy, which includes,
                Google OAuth (governed by the Google API Terms of Service and
                the Google API Services User Data Policy) and Facebook
                Authentication (governed by the Facebook Platform Terms),
                including the Limited Use requirements.
              </li>
            </ol>
          </div>
          <div className="privacy-subbtext-heading">
            HOW DO WE USE YOUR INFORMATION?
          </div>
          <div className="privacy-subtext-paragraph">
            We use the Personal Information and Non-Personal Information
            provided by you:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                To create your account, verifying your identity and process your
                transaction(s) on the Platform;
              </li>
              <li style={{ marginTop: "1%" }}>
                To collect statistics about your usage of the Platform and its
                effectiveness;
              </li>
              <li style={{ marginTop: "1%" }}>
                To improve and personalise your experience of the Services, as
                well as customize our interactions with you to further expand
                the scope of the Platform;
              </li>
              <li style={{ marginTop: "1%" }}>
                To resolve disputes, customer support and troubleshoot problems
                that arise from using our Platform;
              </li>
              <li style={{ marginTop: "1%" }}>
                To help promote a safe service on the Platform and protect the
                security and integrity of the Platform, the Services, and the
                users;
              </li>
              <li style={{ marginTop: "1%" }}>
                To collect the Fees from you in relation to the Services;
              </li>
              <li style={{ marginTop: "1%" }}>
                To inform you about online and offline offers, products,
                services and/or updates;
              </li>
              <li style={{ marginTop: "1%" }}>
                To customise your experience on the Platform or share custom
                marketing material with you;
              </li>
              <li style={{ marginTop: "1%" }}>
                To detect, prevent and protect the Company from any errors,
                frauds, and other criminal or prohibited activity on the
                Platform;
              </li>
              <li style={{ marginTop: "1%" }}>
                To enforce and inform you about our Terms of Service;
              </li>
              <li style={{ marginTop: "1%" }}>
                To process and fulfil your request for Services or respond to
                your queries on the Platform;
              </li>
              <li style={{ marginTop: "1%" }}>To contact you;</li>
              <li style={{ marginTop: "1%" }}>
                To communicate important notices or changes in the Services
                offered by the Company, use of the Platform, and the
                terms/policies which govern the relationship between you and the
                Company and with our affiliates (if applicable); and
              </li>
              <li style={{ marginTop: "1%" }}>
                For any other purpose, for which you have granted access to such
                information to us, based on your interaction with Platform
              </li>
            </ol>
            You specifically agree and consent to us collecting, storing,
            processing, transferring, and sharing your Personal Information and
            Non-Personal Information for the aforementioned use-cases. We may
            send you text messages on your registered mobile number that you
            share with us. All text messages originating from us, where
            applicable, through our authorized third-party service providers
            will at all times be compliant with guidelines prescribed by the
            Telecom Regulatory Authority of India (“TRAI”). Nature of
            communication from us is broadly classified into two (2) categories:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                Transactional SMS. Transactional messages are messages that
                carry your account and transaction specific information such as
                one-time passwords in connection with registration for any of
                the Services or campaign related offerings, account status,
                invoice details, confirmation alerts, bank accounts status and
                other details in the form of real-time updates on the activities
                on your account. You may receive transactional Messages even if
                you are a Do-Not-Disturb (“DND”) registered user.
              </li>
              <li style={{ marginTop: "1%" }}>
                Promotional SMS. You may also receive text messages in the event
                that you are a non-DND registered user. We may send you such
                text messages to update you on our service offerings that may be
                of interest for you.
              </li>
            </ol>
          </div>
          <div className="privacy-subbtext-heading">
            TO WHOM DO WE DISCLOSE YOUR INFORMATION?
          </div>
          <div className="privacy-subtext-paragraph">
            We will share your Personal Information and Non-Personal
            Information,
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                with (i) competent legal / statutory / regulatory agencies /
                authorities, or (ii) partners / service providers acting on our
                behalf (as the case may be) to the extent necessary, in the
                following cases:
                <ol type="lower-alpha" style={{ listStyleType: "lower-alpha" }}>
                  <li style={{ marginTop: "1%" }}>
                    To enable the delivery of the Services availed by you,
                    strictly on a “need to know” basis, in the ordinary course
                    of business, and subject to applicable laws;
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    To protect the rights, property and safety of the Bridgit,
                    our business and Services, partners, service providers and
                    the users, including to enforce the Terms of Service;
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    When directed by or required by legal / regulatory /
                    statutory / governmental authorities under applicable laws
                    and to respond to lawful requests and due legal process; or
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    When required by financial institutions to verify, mitigate
                    or prevent fraud or to manage risk or recover funds in
                    accordance with applicable laws.
                  </li>
                </ol>
              </li>
              <li style={{ marginTop: "1%" }}>
                in case of a corporate sale, merger, acquisition, sale of
                business, reorganization, dissolution, insolvency, or such
                similar events, on an aggregate basis
              </li>
            </ol>
            Please note that we will not rent, lease and sell any information to
            third parties, without your consent.
            <br></br>
            <br></br>
            Please note that our Platform may link you to third-party
            websites/apps (“Third-Party Sites”), that may collect your personal
            information, for which the Company is not in any manner responsible
            for the security of such Third-Party Sites or their privacy
            practices or content. Such third-party provides Third-Party Sites
            may have their own privacy policies governing their storage and
            retention of your information that you may be subject to. This
            Privacy Policy does not govern any information provided to, stored
            on, or used by such Third-Party Sites or third-party providers and
            we recommend that you review the applicable privacy policy when you
            enter a Third-Party Site. You agree and acknowledge that the Company
            is not liable for the information published by any Third-Party Site.
            We take care to allow your Personal Information and Non-Personal
            Information to be accessed only by those who really need it in order
            to perform their tasks and duties and to third parties who have a
            legitimate purpose for accessing it and with your consent. Your
            Personal Information and Non-Personal Information will be
            transferred only to a Third-Party Sites that ensures the same level
            of data protection that is mandated under the IT Act and the SDPI
            Rules.
          </div>
          <div className="privacy-subbtext-heading">
            HOW DO WE RETAIN YOUR INFORMATION?
          </div>
          <div className="privacy-subtext-paragraph">
            We may store and retain your Personal Information and Non-Personal
            Information, to the extent necessary:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                To carry out the Services for the user on the Platform.
                Generally, this means, we will keep your Personal Information
                and Non-Personal Information as long as your account is active
                or as needed to provide our Services;
              </li>
              <li style={{ marginTop: "1%" }}>
                Retain and use the information collected as necessary to comply
                with our legal obligations, resolve disputes or for other
                business purposes;
              </li>
              <li style={{ marginTop: "1%" }}>
                Retain your Personal Information and Non-Personal Information in
                accordance with applicable laws, after you cancel/ deactivate/
                unsubscribe your account with us, as a part of our business
                practice; and
              </li>
              <li style={{ marginTop: "1%" }}>
                Where we have no continuing legitimate business need to process
                your Personal Information and Non-Personal Information, we will
                either delete or anonymize it or, if this is not possible (for
                example, because your personal data has been stored in backup
                archives), then we will securely store your personal data and
                isolate it from any further processing until deletion is
                possible.
              </li>
            </ol>
          </div>
          <div className="privacy-subbtext-heading">
            HOW DO WE SECURE YOUR DATA?
          </div>
          <div className="privacy-subtext-paragraph">
            We are dedicated to guarding the security of your information and
            our Platform has several industry-standard security technologies and
            procedures devised to help protect your data from unauthorized
            access, use, destruction or disclosure, including technical and
            organizational security measures to protect the security of your
            personal data both online and offline including the implementation
            of access controls, implementation of firewalls, security and
            penetration tests, secure servers, network intrusion detection and
            use of anti-virus and anti-malware software. Please note that no
            system is completely secure and so while we strive to protect your
            Personal Information and Non-Personal Information, we cannot
            guarantee that unauthorized access, hacking, data loss or a data
            breach of our security measures will never occur. It is further
            clarified that you have and so long as you access and/or use the
            Platform (directly or indirectly) the obligation to ensure that you
            shall at all times, take adequate physical, managerial, and
            technical standards, at your end and on your behalf, to preserve the
            integrity and security of your information, including but not
            limited to, the Personal Information and Non-Personal Information
            provided by you.
            <br></br>
            <br></br>
            You also expressly consent and agree to the sharing of yours
            Personal Information and payment related information, to the extent
            necessary, with third-party service providers, including payment
            gateways and payment processors, to process and manage your payment
            related information. Such payment information while transmitted
            through our Platform, shall be secured with industry-standard
            encryption technology offered by the third-party service providers.
            The Company cannot guarantee that transmissions of your payment
            related information or Personal Information will always be secure or
            that unauthorized third parties will not be able to overcome the
            security measures taken by the Company or the third-party service
            providers. The Company thus assumes no responsibility or liability
            for disclosure of your information due to errors in transmission,
            unauthorized third-party access, or other causes beyond its control.
          </div>
          <div className="privacy-subbtext-heading">
            HOW DO YOU UPDATE YOUR PERSONAL DATA?
          </div>
          <div className="privacy-subtext-paragraph">
            You specifically consent and agree that the Personal Information
            pertaining to your account on the Platform shall be both accurate
            and current. If you wish to update your Personal Information which
            is inaccurate or incorrect, we shall provide self-help tools for you
            to directly review or update certain aspects of your Personal
            Information in our records. As per the existing legal regulations,
            specific data fields (such as name, Aadhar number, address etc.) in
            your Personal Information would be locked in our systems once you
            enter that information in our Platform. If any part of your Personal
            Information changes during the course of your engagement with us and
            your use of our Platform, please use these self-help tools provided
            by us to update that Personal Information. In addition to being able
            to update and correct your personal data, you may also have other
            data protection rights, including:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                The right to withdraw your consent at any time, wherein we have
                collected and processed your personal data with your consent
                pursuant to this Privacy Policy. However, you agree that
                withdrawing your consent will not affect the lawfulness of any
                processing we carried out before your withdrawal, nor will it
                affect processing of your Personal Information carried out in
                reliance on other lawful grounds other than consent. You also
                agree that in case you do not provide consent or later withdraw
                your consent, we request you to not access the Platform and/or
                use the Services. In such a scenario, we also reserve the right
                to not provide you any Services through the Platform and may
                delete your information or de-identify it so that it is
                anonymous and not attributable to you.
              </li>
              <li style={{ marginTop: "1%" }}>
                The right to ask for a copy of your Personal Information in a
                portable (machine-readable) format.
              </li>
              <li style={{ marginTop: "1%" }}>
                The right to deny us from using your Personal Information for
                direct marketing other campaigns/programmes.
              </li>
              <li style={{ marginTop: "1%" }}>
                The right to make any of the above requests by contacting us at
                support@bridgit.club. We will respond to all requests in
                accordance with the applicable laws.
              </li>
            </ol>
          </div>
          <div className="privacy-subbtext-heading">
            WILL WE CHANGE THE PRIVACY POLICY?
          </div>
          <div className="privacy-subtext-paragraph">
            We reserve the right, at our sole discretion, to revise, change or
            modify this Privacy Policy from time to time and such modifications
            shall be binding on only upon your acceptance. Therefore, we urge
            you to review the Privacy Policy periodically to ensure that you
            agree with our latest information on our privacy practices. We will
            make best efforts to inform you of any amendments by e-mail oy
            notification in your account on the Platform. However, it is your
            responsibility to check the Privacy Policy regularly to ensure that
            you agree with it and your continued use of the Platform will be
            deemed to be your acceptance of any changes that we make.
          </div>
          <div className="privacy-subbtext-heading">COPYRIGHT POLICY</div>
          <div className="privacy-subtext-paragraph">
            The contents of this website and the Platform may not be reproduced
            partially or fully, without due permission from Bridgit, as per the
            Copyright Act, 1957. If referred to as a part of another
            publication, the source must be appropriately acknowledged. The
            contents of this website cannot be used in any misleading or
            objectionable context.
          </div>
          <div className="privacy-subbtext-heading">
            GOVERNING LAW & DISPUTE RESOLUTION
          </div>
          <div className="privacy-subtext-paragraph">
            Any Dispute that may arise in respect of your use (directly or
            indirectly) of our Platform shall be governed by all laws applicable
            within the territory of India. You consent and agree that any and
            all disputes with respect to this Privacy Policy, our Services, and
            the Platform (collectively “Disputes,” shall be resolved through
            mandatory, binding arbitration, which shall be governed under the
            Indian Arbitration and Conciliation Act, 1996. As such, you agree to
            waive your respective right to have any and all Disputes arising
            from or related to this Privacy Policy, and/or your use of our
            Services or Platform, resolved in a competent court. Instead, you
            and we agree to arbitrate Disputes through binding arbitration
            (which is the referral of a Dispute to one or more persons charged
            with reviewing the Dispute and making a final and binding
            determination to resolve it instead of having the Dispute decided by
            a judge in court). We agree that the seat of jurisdiction of such
            arbitration for the Dispute will be held in Bangalore, India.
          </div>
          <div className="privacy-subbtext-heading">
            PRIVACY QUESTIONS AND GRIEVANCE REDRESSAL
          </div>
          <div className="privacy-subtext-paragraph">
            If you have any questions, concerns, or suggestions regarding our
            Privacy Policy, please reach out to us using the contact information
            on our “Contact Us” page or at support@bridgit.club. If you have any
            grievance or complaint with respect to the Platform, please reach
            out to the grievance officer, the name and contact details provided
            below: Name: Aruna Singh E-mail: support@bridgit.club We will
            acknowledge your complaint within a period of 36 (thirty) hours and
            share the complaint registration number to enable you to track the
            status of the complaint for future correspondence. The grievance
            office shall investigate the complaint and shall remove any
            violative content, if any, and shall resolve the matter within a
            period of 1 (one) month. The grievance officer shall provide you
            with a summary of the resolution of the compliant after this
            process.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
