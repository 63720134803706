import React, { useRef } from "react";
import "./happystories.css";
import preeti from "../../images/Preeti-image.png";
import bhavya from "../../images/Bhavya-image.png";
import akshay from "../../images/Akshay-image.png";
import naina from "../../images/Naina-image.png";
import praveen from "../../images/Praveen-image.png";
import richa from "../../images/Richa-image.png";
import reetika from "../../images/Reetika-image.png";
import vishal from "../../images/Vishal-image.png";
import prachi from "../../images/Prachi-image.png";
import amit from "../../images/Amit-image.png";

const HappyStories = () => {
  const happyStories = useRef(null);

  return (
    <div className="hs-section" id="testimonial" ref={happyStories}>
      <div className="hs-wrapper">
        <h2 className="hs-heading">
          <span className="hs-span-our">Our </span>
          <span className="hs-span-happy">Happy</span>
          <span className="hs-span-stories"> Stories</span>
        </h2>

        <div className="hs-carousel-container">
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={preeti}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Preeti Kumar</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Highly highly recommended
                </h3>
                <p className="hs-user-review-subtext">
                  Bridgit's advisors suggestions were backed by a thorough
                  understanding of how the system works and how I can align it
                  with my long-term saving goals. Even if you feel you are doing
                  the best you can in terms of handling your money, it might
                  turn out that you can do better!
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={bhavya}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Bhavya Kukreja</h3>
                <h4 className="hs-user-business-title">Social Worker</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Helped me explore endless investment opportunities
                </h3>
                <p className="hs-user-review-subtext">
                  Financial journey always sounded arduous to me. Working in an
                  NGO and managing finances seemed challenging. Bridgit's advice
                  to first save and then manage the expenses gave me confidence
                  than living each day with regret.
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={akshay}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Akshay Talwar</h3>
                <h4 className="hs-user-business-title">Entrepreneur</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  I started my investments right after my bridgit session
                </h3>
                <p className="hs-user-review-subtext">
                  Lack of financial knowledge is something that has affected
                  most of us. I had many questions in mind before booking a
                  bridgit session. My financial planning was limited to FDs and
                  real estate. My bridgit advisor helped burst this bubble. He
                  showed me endless investment opportunities with amazing ROIs
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={naina}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Naina Arora</h3>
                <h4 className="hs-user-business-title">Business Women</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Gave actionable ways to grow my portfolio
                </h3>
                <p className="hs-user-review-subtext">
                  I was a bit lost on how I can invest my money further. I was
                  already investing in Mutual Funds for the past four years but
                  wanted to explore other options as well. Recently, I have been
                  reading about small-case, cryptocurrency but the more I read
                  about it, the more confused I got.
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={praveen}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Praveen Kumar</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  For everyone who needs financial management
                </h3>
                <p className="hs-user-review-subtext">
                  I have been earning for the last 4 years but I did not have
                  any financial plans, goals, saving strategies, etc. Bridgit is
                  the go-to place if you need any help with personal finance. My
                  advisor explained everything and also shared a framework with
                  me to start my finance journey.
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={richa}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Richa Arora</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Gave me advice per my needs & future plans
                </h3>
                <p className="hs-user-review-subtext">
                  I have been earning for the past 5 years and wanted to
                  understand how can I improve my portfolio and allocation of
                  income into various buckets. My Bridgit advisors gave me
                  advice as per my needs and future plans.
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={reetika}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Reetika Rahlan</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  A place to get answers to all my financial questions!
                </h3>
                <p className="hs-user-review-subtext">
                  I wanted insights on what an investment portfolio should be
                  like, what all assets can I invest in, and also how much I
                  should be investing. Bridgit gave me the platform to address
                  all these questions.
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={vishal}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Vishal R Singh</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Gave me smart ways to have a balanced financial health
                </h3>
                <p className="hs-user-review-subtext">
                  One of the best thing that I've come across in the financial
                  literacy field in recent times. Enlightened me with some
                  interesting and smart ways to invest. I've been working as a
                  faculty and content creator, did earn well but never knew
                  about the balance...
                </p>
              </div>
            </div>
          </div>
          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={prachi}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Prachi Rana</h3>
                <h4 className="hs-user-business-title">Working Professional</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Extremely helpful session, Explained every detail
                </h3>
                <p className="hs-user-review-subtext">
                  I needed some guidance to better manage my funds and
                  investments and a session with Bridgit came to the rescue.
                  Thier advisor were very patient with my queries and explained
                  everything to me in detailed way.
                </p>
              </div>
            </div>
          </div>

          <div className="hs-user-testimonial">
            <div className="hs-user-col">
              <div className="hs-user-info">
                <img
                  className="hs-user-img"
                  src={amit}
                  alt="bridgit-customer-review"
                ></img>
                <h3 className="hs-user-name">Amit Verma</h3>
                <h4 className="hs-user-business-title">Consulatant</h4>
              </div>
              <div className="hs-user-review">
                <h3 className="hs-user-review-title">
                  Helped me find best investments.
                </h3>
                <p className="hs-user-review-subtext">
                  My bridgit advisor walked me through the initial steps like
                  SIPs (choosing an MF & evaluating it), Term Insurance and
                  other safety nets like liquid funds. I think having an open
                  conversation pulled me out of the procrastination for looking
                  for the best investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyStories;
