import React from "react";
import logo from "../../images/logo-round.png";

const BlogItem = ({ blogTitle, blogPublishDate, imageLink, key, onClick }) => {
  return (
    <div className="blog-items" key={key} onClick={onClick}>
      <img className="blog-item-image" src={imageLink} alt={blogTitle}></img>
      <div className="blog-item-heading">
        <h2 className="blog-item-headingh2">{blogTitle}</h2>
      </div>
      <div className="blog-item-footer">
        <div className="user-profile">
          <img className="profile-pic" src={logo} alt="team-bridgit"></img>
          <h4 className="profile-name">Team Bridgit</h4>
        </div>
        <div className="date-container">
          <h4 className="date">{blogPublishDate}</h4>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
