import React from "react";
import config from "../../../shared/config";
const RelatedArticles = ({
  blogTitle,
  blogSlug,
  blogPublishDate,
  Imagesrc,
}) => {
  return (
    <a
      className="relatedBlogs"
      href={config.get(process.env.NODE_ENV)["WEB_URL"] + "/blogs/" + blogSlug}
    >
      <img className="relatedBlog-Image" src={Imagesrc} alt={blogTitle}></img>
      <div className="relatedBlog-content">
        <h4 className="relatedBlogs-heading">{blogTitle}</h4>
        <h5 className="relatedBlogs-date">{blogPublishDate}</h5>
      </div>
    </a>
  );
};

export default RelatedArticles;
