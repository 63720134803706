import React from "react";
import "./termsofservice.css";
import logo from "../../images/logo-Bridgit.svg";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  return (
    <div className="tos-section" id="termsofservice">
      <div className="tos-section-wrapper">
        <div className="tos-logo">
          <Link to="/">
            <img src={logo} style={{ width: "250px" }} />
          </Link>
        </div>
        <div className="tos-heading">Terms of Service</div>
        <div className="tos-container">
          <div className="tos-subbtext-heading">1. INTRODUCTION</div>
          <div className="tos-subtext-paragraph">
            Thank you for using Bridgit Financial Advisors Private Limited’s
            (hereinafter referred to as “Bridgit") platform (hereinafter
            referred to as the “Platform") and Services (as defined below)
            through Bridgit’s mobile application and website (hereinafter
            referred to as the “App” or “Website”, as the context may require).
            This Terms of Service (hereinafter referred to as the “Agreement” or
            “Terms of Service”) is a legally binding agreement between you
            (hereinafter referred to as the “You”, “Your”, "User(s)” and the
            meaning shall include members of Your family sub-accounts) and
            Bridgit and/or affiliates (together referred to as “We”, “Us” or
            “Our(s)”), governing the rights and obligations between You and
            Bridgit in respect of the Platform and Services.
            <br></br>
            <br></br>
            You acknowledge and agree that Your use of the Platform and the
            Services shall constitute Your acceptance of this Agreement and to
            all of the terms and conditions stated under this Agreement, and to
            the Platform’s Privacy Policy referenced herein and any related
            terms.
            <br></br>
            <br></br>
            If You are unsure as to the terms of this Agreement, please do not
            proceed further and contact Us at support@bridgit.club.
            <br></br>
            <br></br>
            THIS PLATFORM TERMS OF SERVICE HAS BEEN DRAFTED IN ACCORDANCE WITH
            THE PROVISIONS OF RULE 3 (1) OF THE INFORMATION TECHNOLOGY
            (INTERMEDIARIES GUIDELINES) RULES, 2011 (AS AMENDED) THAT REQUIRE
            PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF
            SERVICE/ USER AGREEMENT FOR ACCESS OR USAGE OF THE APP AND RELATED
            SERVICES.
            <br></br>
            <br></br>
            THESE TERMS AND THE PLATFORM PRIVACY POLICY ARE COMPLIANT WITH
            INFORMATION TECHNOLOGY (REASONABLE SECURITY PRACTICES AND PROCEDURES
            AND SENSITIVE PERSONAL DATA OR INFORMATION) RULES, 2011 (AS AMENDED)
            WHEREVER APPLICABLE.
            <br></br>
            <br></br>
            PLEASE READ THE TERMS CONTAINED IN THIS AGREEMENT CAREFULLY TO
            ENSURE THAT YOU UNDERSTAND EACH PROVISION. PLEASE NOTE THAT THESE
            TERMS CONTAIN A BINDING AND MANDATORY ARBITRATION PROVISION AND
            LIMITS REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.
            <br></br>
            <br></br>
            BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING
            YOUR ACCEPTANCE OR BY USING THE PLATFORM BY DOWNLOADING AND
            INSTALLING THE APP, YOU AGREE THAT (A) YOU HAVE READ AND UNDERSTOOD
            THE AGREEMENT; (B) REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD; (C)
            YOU CAN FORM A BINDING CONTRACT AS SET OUT UNDER INDIAN CONTRACT
            ACT, 1872; AND (D) YOU ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE
            LEGALLY BOUND BY ITS TERMS AND THE PLATFORM PRIVACY POLICY,
            REFERENCED HEREIN.
            <br></br>
            <br></br>
            WE RESERVE THE RIGHT TO REFUSE ACCESS TO THE PLATFORM IF IT IS
            BROUGHT TO OUR NOTICE OR WE GATHER THAT YOU ARE “INCOMPETENT TO
            CONTRACT”. PERSONS WHO ARE “INCOMPETENT TO CONTRACT” WITHIN THE
            MEANING OF THE INDIAN CONTRACT ACT, 1872, INCLUDING BUT NOT LIMITED
            TO, MINORS, UNDISCHARGED INSOLVENTS, ETC., ARE NOT ELIGIBLE TO USE
            THE PLATFORM.
            <br></br>
            <br></br>
            You represent and warrant to Us that You will use the Platform in a
            manner consistent with all Applicable Laws. We reserve the right to
            refuse access to use the Platform or to terminate access granted to
            existing Users at any time without according any reasons for doing
            so.
          </div>
          <div className="tos-subbtext-heading">2. DEFINITIONS</div>
          <div className="tos-subtext-paragraph">
            In this Agreement, unless otherwise provides, the following words
            shall mean:
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li style={{ marginTop: "1%" }}>
                “Applicable Laws” means any applicable Indian statutes, laws,
                ordinances, regulations, rules, orders, by-laws, administrative
                interpretation, writ, injunction, directive, judgment or decree
                or other instruments which has a force of law in India, as is in
                force from time to time;
              </li>
              <li style={{ marginTop: "1%" }}>
                “Business Day” means any day, which is not a Saturday, Sunday or
                any other public or gazetted holiday as declared by the
                Government and when both banks and the capital markets are
                operational;
              </li>
              <li style={{ marginTop: "1%" }}>
                “Confidential Data” means any information related to the User,
                which the User shares with Bridgit or submits on the App and/or
                Website and the proprietary information in relation to Bridgit
                or which the User may have come into possession of or pursuant
                to this Agreement; and
              </li>
              <li style={{ marginTop: "1%" }}>
                “Regulation(s)” means the Securities and Exchange Board of India
                (Investment Advisers) Regulations, 2013, as amended and modified
                from time to time, including the circulars and notifications
                issued pursuant thereto.
              </li>
            </ol>
          </div>
          <div className="tos-subbtext-heading">
            3. THE PLATFORM AND SERVICES
          </div>
          <div className="tos-subtext-paragraph">
            <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
              <li>
                Bridgit, through its Platform, allows You to build Your own
                financial plan (retirement planning, investment planning and
                goal planning among other categories) purely based on the
                information provided by You on the Platform and alternate
                sources of relevant information, including: (i) data retrieved
                from CRIF/CAMs Account Aggregation/ICRA; and (ii) assumptions
                and market data for projections. You may utilise the
                functionalities made available by Us to build Your plan. We use
                a combination of cutting-edge technology and common sense, that
                guides You at every step of Your journey, during and even after
                Your lifetime. All results generated by the Platform is made
                available on as-is and available basis based on the information
                You provide Us and the aforementioned alternate sources. The
                Platform also provides financial planning and monitoring tools
                to the Users, and data from third party service partners and
                personal finance content (“Services”). The Platform and the
                Services are catered to individual end-users for their personal
                use only and not for commercial purposes.
              </li>
            </ol>
          </div>
          <div className="tos-subbtext-heading">
            4. GENERAL TERMS AND CONDITIONS FOR THE USE OF THE PLATFORM
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "1%" }}
          >
            1. LIMITED
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  Subject to Your compliance with the terms of this Agreement,
                  Bridgit grants You a personal, limited, non-exclusive,
                  revocable, non-transferable, non-licensable, non-sublicensable
                  license to access and use the Platform and the Services
                  thereunder, and content made available in or otherwise
                  accessible through the Platform strictly in accordance with
                  this Agreement.
                </li>
                <li style={{ marginTop: "1%" }}>
                  You expressly agree not to:
                  <ol
                    type="lower-alpha"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li style={{ marginTop: "1%" }}>
                      duplicate, copy, adapt, modify, prepare derivative works
                      based on the Platform to license, sublicense, publish,
                      broadcast, transmit, distribute, perform, display, sell,
                      rebrand, otherwise transfer or commercially exploit the
                      Platform (excluding any User content);
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      reverse engineer, download, decompile, disassemble,
                      decipher, capture screen shots, or otherwise attempt to
                      derive the source code for any underlying intellectual
                      property used to provide the Platform or any part thereof;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      utilise information, content or any data You view on
                      and/or obtain from the Platform to provide any service
                      that is competitive with us;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      imply or state, directly or indirectly, that You are
                      affiliated with or endorsed by us, unless You have entered
                      into a written agreement with us;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      adapt, modify or create derivative works based on the
                      Platform or technology underlying the Platform or other
                      Users’ content, in whole or in part;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      rent, lease, loan, trade, sell/re-sell access to the
                      Platform or any information therein, or the equivalent, in
                      whole or part;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      access, reload, or make any other request to transactional
                      servers that are beyond generally accepted usage of mobile
                      applications;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      use malware or any other means that impair functioning of
                      the App and/or Website;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      use automated methods to add contacts or send messages;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      engage in “framing”, “mirroring”, or otherwise simulating
                      the appearance or function of the App;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      attempt to or actually access the App and/or the Platform
                      by any means other than through the interface provided by
                      Bridgit;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      attempt to or actually override any security component
                      included in or underlying the App and/or the Platform;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      engage in any action that interferes with the proper
                      working of or places an unreasonable load on Our
                      infrastructure, including but not limited to unsolicited
                      communications, attempts to gain unauthorised access, or
                      transmission or activation of computer viruses;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      remove any copyright, trademark or other proprietary
                      rights notices contained in or on the App and on the
                      Platform including those of both Bridgit or any of Our
                      partners;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      use any information obtained from the App and/or the
                      Platform to harass, abuse or harm another User;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      impersonate another User or allow any other User to use
                      Your account identification;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      violate the provisions of the Information Technology Act,
                      2000 and rules and regulations thereunder (as updated) and
                      any applicable data protection laws;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      host, display, upload, modify, publish, transmit, update
                      or share any information that is grossly harmful,
                      harassing, blasphemous, defamatory, obscene, pornographic,
                      paedophilic, libellous, invasive of another’s privacy,
                      hateful, or racially, ethnically objectionable,
                      disparaging, relating or encouraging money laundering or
                      gambling, or otherwise unlawful in any manner whatsoever,
                      or infringes any patent, trademark, copyright or other
                      proprietary rights of a third-party or impersonates
                      another person or threatens public health or safety;
                      promotion of cigarettes or any other tobacco products or
                      consumption of intoxicant including alcohol or threatens
                      critical information infrastructure;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      engage in any of the following restricted activities,
                      including but not limited to, abusing any of the
                      representatives of the organization, indulge in fraudulent
                      activities on the system, using mass media and/or bots to
                      engage with the platform, using mass media and/or bots to
                      malign the organization’s reputation; and
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      employ any deep link, robot, scraper, spider (or other
                      similar automated data gathering, program, methodology or
                      extraction tools, algorithm to monitor, acquire, copy or
                      access the App, Website and/or the Platform, or any
                      portion of the Platform including the underlying
                      algorithm, without Bridgit’s express and prior written
                      consent.
                    </li>
                  </ol>
                  <li style={{ marginTop: "1%" }}>
                    When accessing the Platform, You need to make sure that Your
                    internet connection is adequate. You are solely responsible
                    for Your hardware, hardware compatibility and internet
                    connection including and not limited to the applicable
                    charges, rates, tariffs, and other fees that might apply.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    The Platform acts as a medium connecting Users, partners,
                    and service providers. Other than as expressly set out in
                    these Terms, We do not make any specific promises about the
                    Services. We don’t make any commitments about the content
                    within the Services, the specific functions of the Services,
                    or their availability to You at all times. Further, You
                    shall be solely responsible for any investment decision
                    taken by You on the Services and Bridgit shall not be liable
                    for any loss or damage caused to You or other users of this
                    application due to such investment decision, or any kind of
                    reliance upon it.
                  </li>
                </li>
              </ol>
            </div>
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "3%" }}
          >
            2. OWNERSHIP, PROPRIETORY INFORMATION AND INTELLECTUAL PROPERTY
            RIGHTS
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  You agree that the Platform including the graphics, Our
                  trademarks and editorial content contains proprietary content,
                  information, and material, which are owned by Bridgit and/or
                  Our licensors, including Our customers, brands and agencies
                  and partners, and are protected by applicable intellectual
                  property and other laws, including but not limited to
                  copyright. You agree that You will not use such proprietary
                  content, information or materials other than for Your
                  permitted use of the Platform or in any manner that is
                  inconsistent with the terms contained in this Agreement
                </li>
                <li style={{ marginTop: "1%" }}>
                  You acknowledge and agree that the Platform is provided for
                  Your use. Except to the extent necessary to access and use the
                  Platform, nothing in this Agreement grants any title or
                  ownership interest in or to any copyrights, patents,
                  trademarks, trade secrets or other proprietary rights in or
                  relating to the App/Website and the Platform whether
                  expressly, by implication, by estoppel, or otherwise. Bridgit,
                  its licensors, partners, and service providers reserve and
                  will retain their entire right, title, and interest in and to
                  the content on the Platform including all copyrights,
                  trademarks, and other intellectual property rights therein or
                  relating thereto, except as expressly granted to You in this
                  Agreement.
                </li>
              </ol>
            </div>
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "3%" }}
          >
            3. PRIVACY POLICY
            <div className="tos-subtext-paragraph">
              The Platform’s Privacy Policy, which shall be incorporated into,
              subject to, be read harmoniously with, and in conjunction with the
              Terms of Service, describes how Bridgit will handle the personal,
              non-personal and financial information You provide to Us when You
              register to use the Platform and the Services. You understand that
              through Your use of the Platform, You consent to the collection
              and use (as set forth in the Platform’s Privacy Policy) of this
              information, including the transfer of this information to other
              entities for storage, processing and use by Bridgit, its partners
              and Our affiliates
            </div>
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "3%" }}
          >
            4. ELIGIBILITY
            <div className="tos-subtext-paragraph">
              To be eligible to use the Platform and the Services thereunder,
              You must meet the following criteria and represent and warrant
              that You:
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  are not Our competitor, or are not using the Platform for
                  reasons that are in competition with us;
                </li>
                <li style={{ marginTop: "1%" }}>
                  will only maintain one registered account at any given time;
                </li>
                <li style={{ marginTop: "1%" }}>
                  have full power and authority to enter into and comply with
                  the provisions of this Agreement and doing so will not violate
                  any other agreement to which You are a party;
                </li>
                <li style={{ marginTop: "1%" }}>
                  will not violate any of Our or any third-party rights,
                  including intellectual property rights such as patent,
                  copyright, and trademark rights; and
                </li>
                <li style={{ marginTop: "1%" }}>
                  agree to provide at Your cost all equipment, browser software,
                  and internet access necessary to use the Platform.
                </li>
              </ol>
            </div>
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "3%" }}
          >
            5. ACCESS RIGHTS AND RESTRICTIONS
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  You can access and use the Platform via (i) the App which can
                  be downloaded and installed from the Google Play Store and
                  Apple App Store only, or (ii) the Website at
                  (https://www.bridgit.club/). You agree that Bridgit has the
                  right to disable Your access and use rights, at any time if,
                  in Bridgit’s opinion, You have violated any provision of this
                  Agreement and/or the Platform’s Privacy Policy. You agree to
                  cooperate with Bridgit if the security of the Platform is
                  compromised by You or another person, through the use of the
                  App and/or Website. Bridgit will not be liable for any loss or
                  damage arising from Your failure to comply with this Section
                  4.5.
                </li>
                <li style={{ marginTop: "1%" }}>
                  We collect personal and financial information (as set forth in
                  the Platform’s Privacy Policy), which We need from You when
                  You register to use the Platform and Our Services. This
                  information is necessary for Us to provide the Platform and
                  the Services to You and is stored on Our servers to enable Us
                  to continue to provide the Platform and the Services to You.
                  Upon Your written request, Bridgit will provide You with a
                  list of all personal information that We store concerning You
                  within sixty (60) days of receiving Your request. Also, upon
                  Your prior written request, Bridgit will delete any such
                  information upon receiving Your request, subject to Applicable
                  Laws, including the Regulations. Notwithstanding the above,
                  please note that, if You ask Bridgit to delete all such
                  information, We will not be able to continue to provide the
                  the Platform and/or Our Services to You.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Territory: The Platform is only available for use within
                  India. You must comply with this Platform’s Terms of Service
                  and Platform’s Privacy Policy. If You are a resident of any
                  other country, You may not use the Platform.
                </li>
                <li style={{ marginTop: "1%" }}>
                  If You have any questions regarding this Section, please email
                  Us at support@bridgit.club.
                </li>
              </ol>
            </div>
          </div>
          <div
            className="tos-subbtext-heading"
            style={{ marginLeft: "5%", marginTop: "3%" }}
          >
            6. USER DECLARATIONS
            <div className="tos-subtext-paragraph">
              You agree, acknowledge and declare that:
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  You shall comply with all Applicable Laws;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You are availing the Services offered on the Platform out of
                  Your free will and volition;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You shall provide accurate information to Bridgit and update
                  such information from time to time as may be necessary;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You are competent to contract within the meaning of the Indian
                  Contract Act, 1872;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You shall review and comply with notices sent by Bridgit, if
                  any, concerning the Platform and Services;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You shall review the Platform’s Privacy Policy, regularly as
                  may be amended;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You are well informed about the risks of investing in
                  financing instruments of any kind and acknowledge and take
                  sole and full responsibility for Your investment choices and
                  decisions You make and/or the performance of Your portfolio,
                  including obtaining Investment Advisory Services (as defined
                  below);
                </li>
                <li style={{ marginTop: "1%" }}>
                  You will be responsible for maintaining the confidentiality of
                  Your account information and Your login credentials safe and
                  confidential against unauthorized access to the Platform
                  and/or Services;
                </li>
                <li style={{ marginTop: "1%" }}>
                  You will not engage in any of the following restricted
                  activities, including but not limited to, abusing any of the
                  representatives of the organization, indulge in fraudulent
                  activities on the system, using mass media and/or bots to
                  engage with the platform, using mass media and/or bots to
                  malign the organization’s reputation.
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            5. TERMS AND CONDITIONS FOR INVESTMENTS IN MUTUAL FUNDS
            <div
              className="tos-subbtext-heading"
              style={{ marginLeft: "5%", marginTop: "3%" }}
            >
              1. OVERVIEW
              <div className="tos-subtext-paragraph">
                <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                  <li style={{ marginTop: "1%" }}>
                    Bridgit shall facilitate online transactions and provide
                    investment advisory services in respect of various
                    investment products including but not limited to mutual
                    funds, bonds, bank and/ or corporate deposits, Employee
                    Provident Fund, Provident Fund, various Government savings
                    schemes, gold, real estate, Reits, Invits, insurances etc.
                    for its Users (“Investment Advisory Services”).
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    Bridgit offers the Users the option to avail Investment
                    Advisory Services on the Platform, which includes detailed
                    risk profiling, goal planning preparation of investment
                    plan, suggestions with respect to insurance, etc. As a part
                    of the Investment Advisory Services, Bridgit also offers
                    Execution Services and tracking facilities. Bridgit does not
                    make it binding on the Users availing the Investment
                    Advisory Services to avail of the Execution Services through
                    the Platform. The User understands that when they use
                    third-party execution service providers for transaction
                    execution and not the Execution Services offered on the
                    Platform, Bridgit and the Platform may not be able to offer
                    portfolio tracking, portfolio rebalancing, and any such
                    services that require awareness of the User’s investment
                    portfolio, unless the User explicitly adds the details of
                    such transaction execution on the Platform.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    Bridgit has entered into agreements with third party
                    technology service providers to link up with banks for
                    offering various facilities through the internet, including
                    net banking and payment and settlement facilities in respect
                    of payment instructions initiated by the User using
                    third-party payment gateways. Accordingly, Investment
                    Advisory Services is available to those Users who hold a
                    bank account with these banks and have availed the facility
                    for online payments / net banking through third party
                    gateways.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    It is hereby expressly clarified that Bridgit engage in any
                    distribution activity for the Users who use its Investment
                    Advisory Services. Bridgit provides the Platform as a medium
                    for such Users to facilitate the transaction of investment
                    in mutual funds and other investment products. Bridgit may
                    offer distribution services in mutual funds and other
                    products through a separately identifiable department or
                    division (“SID”) as per the applicable rules and regulations
                    of SEBI.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    Mutual fund investment facility may be made available with
                    respect to select mutual funds only, with whom Bridgit may
                    enter into a separate arrangement either directly or through
                    transaction processing platforms and accordingly provides
                    online Platform to deal in the units of the scheme of these
                    mutual funds that are existing / launched / offered by them
                    from time to time. Bridgit may enhance the number of mutual
                    funds schemes and other products for which it provides
                    execution over time.
                  </li>
                </ol>
              </div>
            </div>
            <div
              className="tos-subbtext-heading"
              style={{ marginLeft: "5%", marginTop: "3%" }}
            >
              2. CONDITIONS FOR REGISTRATION
              <div className="tos-subtext-paragraph">
                <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                  <li style={{ marginTop: "1%" }}>
                    Having an investment account on the Platform is subject to
                    the satisfactory completion of the registration form and
                    submission of all mandatory documents and valid Permanent
                    Account Number (“PAN”) and its subsequent verification and
                    KYC Registration Agency (“KRA”) verification.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    The Platform can be activated for KRA verified investors
                    only. Accordingly, while enrolling for the registration,
                    Your KRA verification process will be checked and You shall
                    provide a copy of valid PAN and proof of completion of KYC
                    through SEBI approved KRA or other details as may be
                    prescribed from time to time and these details shall get
                    verified with KRA.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and authorise Bridgit to submit all the KYC
                    documents (including but not limited to name, PAN card
                    details, age, address and signature) and the User related
                    documents to the respective mutual funds, AMCs and/ or their
                    Registrar and Transfer Agents (“RTA”), other entities
                    through whom or into whom investments shall be made for the
                    purpose of validation and to comply with regulatory
                    requirement notified from time to time.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree that signature available in the KRA
                    records/systems would be used for signature verification for
                    processing any request made by You through the Platform or
                    direct transactions with an AMC and in the event such
                    signature is not available or legible in KRA records/system,
                    You understand and agree that the AMC or other entities
                    shall be entitled to carry out further checks to validate
                    the authenticity of the request / or to reject/ disallow any
                    transaction in the connection herewith.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    Once it is ascertained that the User is KRA verified, Your
                    account on the Platform shall be activated. The account can
                    be operated using the user ID and password that You create
                    during registration. You agree and unconditionally confirm
                    that You shall be solely responsible to ensure protection
                    and confidentiality of the user ID and password at all times
                    and any disclosure thereof shall be entirely at Your risk
                    and accept the sole responsibility to maintain the secrecy
                    of all information of confidential nature and shall ensure
                    that the same is not disclosed to any other person.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree that access to Your account may be suspended /
                    cancelled /terminated without liability, in whole or in
                    part, at any time without prior notice if You do not comply
                    with this Agreement or any modifications thereof.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and understand that no change of address, mobile
                    number, e-mail address and / or bank details can be allowed
                    for such accounts, unless a duly signed hard copy of such
                    written request is sent to the AMC / CAMS / POS for
                    signature verification and other formalities.
                  </li>
                </ol>
              </div>
            </div>
            <div
              className="tos-subbtext-heading"
              style={{ marginLeft: "5%", marginTop: "3%" }}
            >
              3. GENERAL CONDITIONS
              <div className="tos-subtext-paragraph">
                <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                  <li style={{ marginTop: "1%" }}>
                    Investments in mutual fund and other securities (hereinafter
                    referred as “Products” or individually as “Product”) are
                    subject to market risks and there can be no assurance that
                    the objectives of the schemes will be achieved by the User.
                    As with any investment in securities, the value of such
                    Products can go up or down depending on the factors and
                    forces affecting capital markets. There is no guarantee for
                    any returns on investments made in Products. Past
                    performance of Products do not indicate the future
                    performance of the Products and may not necessarily provide
                    a basis of comparison with other investments.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You grant authority to Bridgit to process and transmit such
                    request made by You through Your account authenticated by
                    the user ID and password and authorise Bridgit to execute
                    Your instructions with regards to purchase / redemption /
                    switch / sell or other transactions/ activities in respect
                    of Products on Your behalf, as may be instructed by You from
                    time to time, on the terms and conditions mentioned herein.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and confirm that Bridgit and/or its authorised
                    representative is / are not in a position to verify that the
                    dealings are indeed undertaken by You at the time of dealing
                    through Your account and Bridgit shall be not held
                    responsible for any transactions arising out of the misuse
                    of the password by any person other than You.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You understand and agree that instructions given by You to
                    Bridgit for transacting in Products units will be forwarded
                    to the respective entitites via a transaction processing
                    platform that Bridgit has a prior contractual arrangement,
                    in any specified format and such entitites will process the
                    transaction on the basis of such specified formats (“Feed
                    File”) only. And You hereby agree and consent to the
                    transmission of data by electronic or other means through
                    Your account from the Platform.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree that the data and information provided by You
                    pursuant to Your dealing with Bridgit will be shared by
                    Bridgit, subject to the Privacy Policy, with its authorised
                    agents, representatives, affiliates, legal authorities and
                    service providers for facilitating transaction processing,
                    servicing, data processing, transaction statement generation
                    and for compliance with any legal or regulatory
                    requirements. You agree that submission of a transaction
                    request through Your account shall not be deemed to be
                    acceptance of the transaction by the providers of Products
                    or mutual funds or its RTAs (collectively referred to as
                    “Product Providers” or individually as “Product Provider”)
                    and the transaction could be rejected if does not comply
                    with the provisions of the respective offer document of the
                    Products or for any other reason which Bridgit and / its
                    authorised representatives/Product Providers deem fit.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and acknowledge that, notwithstanding anything
                    contained hereunder, Bridgit may enter into separate
                    arrangements with product providers, including but not
                    limited to, Product Providers, AMCs, insurance companies,
                    ITR filing companies and others for the purpose of
                    providing/ receiving certain ancillary services including in
                    relation to investor education on its Platform, with or
                    without consideration, subject to the Regulations.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and authorise Bridgit to communicate on Your
                    behalf with the Product Providers for financial and /or
                    non-financial transactions and communications.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    The transactions carried out through Your account in units
                    of various Products shall be subject to the provisions
                    contained in the respective product related documents,
                    including but not limited to scheme information document,
                    offer document and statement of additional information or
                    any addendum thereto.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree to comply with KYC (Know Your Customer) and KRA
                    requirements as required under SEBI (KYC Registration
                    Agency) Regulation 2011, SEBI Act 1992, Prevention of Money
                    Laundering Act, 2002, the Regulations, and all laws and
                    regulations including but not limited to the Foreign
                    Exchange Management Act, Reserve Bank of India (RBI)
                    guidelines, to the extent applicable for availing the
                    account on the Platform.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You further agree and acknowledge that the mutual fund/AMC
                    or Product Providers will act only on the electronic
                    transaction data forwarded by Bridgit and will be under no
                    obligation to verify and/or confirm the authenticity of such
                    transactions including the content, source, etc. You hereby
                    confirm that You will not hold the mutual fund/AMC liable
                    and/or responsible for any loss/damage that You may incur as
                    a result of any such improper use of Your password or any
                    unauthorized access to Your mutual fund investment facility.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree and acknowledge that the filling in of transaction
                    data/instructions for onward forwarding to the Products
                    would require You to incorporate proper, accurate and
                    complete details. In the event of Your account receiving an
                    incorrect credit by reason of a mistake committed by any
                    third party, the concerned Product Providers or the bank
                    shall be entitled to reverse the incorrect credit at any
                    time without Your consent.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You shall be liable and responsible to Bridgit/ concerned
                    Product Providers for any unfair or unjust gain obtained by
                    You as a result of the incorrect credit.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    The Product Providers may at its discretion not give effect
                    to Your transactions where the Product Providers have
                    reasons to believe that the transactions are not genuine or
                    are otherwise improper or unclear or raise a doubt, Bridgit
                    will not be responsible for any such action taken by the
                    Product Providers and accordingly You cannot raise the issue
                    with Bridgit. The respective Product Providers shall not be
                    liable for the loss of any opportunity costs incurred by
                    You, if any transactions are not carried out for any reasons
                    whatsoever. You understand that any payment made by You
                    towards such transactions will be refunded to Your account,
                    after deduction of charges, if any.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You may communicate directly with the Product Providers to
                    resolve any clarifications that You may have pertaining to
                    Your investments in the Products. For any transactions that
                    come through offline mode, the signature available in KYC
                    records would be used for signature verification and in the
                    event of such signature not being available or legible, and
                    the Product Providers would be within its rights to carry
                    out further checks to validate the authenticity of the
                    request.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    In the event an account is jointly held, You acknowledge
                    that all instructions (including instructions for
                    modification of this clause) shall be given only by the
                    first holder of such account, on behalf of all of You and
                    for this purpose, the joint investors, irrevocably
                    constitute the first holder above as Your agent. All
                    instructions given by the first holder above shall be
                    binding on all of You as if given by each of You personally.
                    You hereby authorise Bridgit to process and transmit such
                    instructions given from time to time in relation to Your
                    transactions to the respective Product Providers. The mode
                    of holding in all customer joint accounts is treated as
                    “Either or Survivor”.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You understand Bridgit may display curated products of other
                    providers or customised offers for products suited to Your
                    profile information available with us. Bridgit does not
                    endorse any of these third-party providers or provide any
                    warranty regarding such advertisements or offers that may
                    displayed to You.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You acknowledge that dealing through Your account on the
                    Platform will be at Your own risk. You shall solely be
                    responsible to evaluate all the risks arising out of the
                    same. Your account may be susceptible to number of frauds,
                    misuse, phishing, hacking and other actions, which could
                    affect instructions sent to Bridgit or its authorised
                    representatives. There cannot be any guarantee from such
                    internet frauds, phishing, hacking and other cyber-attacks.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            6. SERVICE FEES
            <div className="tos-subtext-paragraph">
              Bridgit offers Users who use the App the option between a free and
              paid subscription for the Services in the Platform, which may be
              amended from time to time (“Service Fees”). Bridgit charges Users
              for the Investment Advisory Services it performs on the Platform,
              as may be notified from time to time. In the event that We change
              this in the future, We will communicate such modification to You
              or as otherwise specified in another agreement between Bridgit and
              You.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            7. CONFIDENTIALITY
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  Bridgit shall maintain the confidentiality of the User’s
                  Confidential Information as per the Platform’s Privacy Policy.
                  Further, We will ensure that any third party, with whom the
                  information is being shared, are under contractual obligations
                  to protect the Confidential Information of the Users, and to
                  use it only to provide the services to the extent as required
                  under the agreement with us. For the avoidance of doubt, it is
                  clarified that Bridgit will not be liable for any claim or
                  defend any breach of Confidential Information of the User
                  owing to third-party acts or omissions.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User agrees not to use or disclose the information
                  provided under the Services to any third party including any
                  discussion forums or for any unauthorised illegal purpose. The
                  User also agrees to use the information provided under the
                  Service strictly for personal purpose.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Notwithstanding the above, Bridgit shall be entitled to
                  disclose any Confidential Information pertaining to You to any
                  authority, statutory or otherwise as may be required by law
                  from time to time.
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            8. INDEMNIFICATION
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  You agree to indemnify, defend, and hold Bridgit, service
                  providers, Product Providers and its officers, employees,
                  managers, directors, customers, and agents (the “Indemnitees”)
                  harmless from and against any and all costs, liabilities,
                  losses and expenses (including but not limited to reasonable
                  attorneys’ fees) resulting from any claim, suit, action,
                  demand or proceeding brought by any third party against Us and
                  Our Indemnitees arising from any of the following:
                  <ol
                    type="lower-alpha"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li style={{ marginTop: "1%" }}>
                      usage of the Platform by the User;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      a breach of this Agreement;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      the negligence, fraud, or wilful misconduct of You or Your
                      joint holders in the family account and/or sub-account;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      incorrect information provided by You in Your account or
                      elsewhere on the Platform or to us;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      a failure by You or Your joint holders in the family
                      account and/or sub-account to comply with Applicable Laws
                      and regulations; and
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      arising directly or indirectly out of the negligence,
                      mistake or misconduct of the User.
                    </li>
                  </ol>
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User agrees not to use or disclose the information
                  provided under the Services to any third party including any
                  discussion forums or for any unauthorised illegal purpose. The
                  User also agrees to use the information provided under the
                  Service strictly for personal purpose.
                </li>
                <li style={{ marginTop: "1%" }}>
                  You agree and undertake not to hold Bridgit, service
                  providers, Product Providers and their officers and agents
                  liable for:
                  <ol
                    type="lower-alpha"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li style={{ marginTop: "1%" }}>
                      any loss caused through a fall in value of investments /
                      NAV, etc., which can go up or down depending on the
                      factors and forces affecting capital markets or any
                      indirect, special or consequential loss You might suffer;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any loss or damage caused by reason of failure / refusal
                      or delay of the mutual fund to deliver any units purchased
                      even though payment have been effected for the same or
                      failure / refusal or delay in making payment in respect of
                      any units sold though they may have been delivered
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any delay, failure or refusal of the Product Providers or
                      its authorised agent / RTAs in registering or transferring
                      units to Your names of for any interest, dividend or other
                      loss caused to You arising there from;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any delay / refusal / non-payment of any proceeds on
                      redemption or dividend pay-outs by the Product Providers
                      to Your accounts for any reason whatsoever;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any delay or failure in processing the transactions
                      carried out by You due to any system failure or for any
                      other reason whatsoever;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      for any loss or damage incurred or suffered by You due to
                      any error, defect, failure or interruption in the dealing
                      arising from or caused by any reason whatsoever;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      for any fraud, negligence/mistake or misconduct by You
                      including failure to comply with the KRA/KYC norms or
                      provision of a valid PAN or PMLA requirements;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any breach or non-compliance by You of this Agreement or
                      the Privacy Policy;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      not permitting You to deal after such reasonable
                      verification as Bridgit/its authorised representative may
                      deem fit;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any loss or damage incurred or suffered by You due to
                      withdrawal or termination or suspension of the dealing
                      facility arising from or caused by any reason whatsoever;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      any loss or damage arising directly or indirectly out of
                      effecting transaction based on instructions received from
                      the User;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      The exercise of the facility/Services provided on the
                      Platform;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Any injury to the credit, character and reputation of the
                      User due to use of facility/ services on the Platform.
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Any misstatement, misrepresentation, error or omission in
                      any details disclosed by Bridgit if it receives any
                      process, summons, order, injunction, execution distrait,
                      levy lien, information or notice which Bridgit in good
                      faith believes/ calls into question the User’s ability, or
                      the ability of someone purporting to be authorised by the
                      User, to make the transfer.
                    </li>
                  </ol>
                  <li style={{ marginTop: "1%" }}>
                    The maximum liability of Bridgit /its authorised
                    representatives/service providers to You shall not exceed
                    the amount of the Investment Advisory Service fees and/or
                    Services Fees paid by You for availing the Services through
                    the Platform for the prior 12 months from the date of any
                    such incident.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    You agree that Bridgit /its authorised representatives
                    /service providers shall be under no obligation to further
                    ascertain Your identification at the time of transactions.
                  </li>
                  <li style={{ marginTop: "1%" }}>
                    Notwithstanding anything contained herein, Bridgit shall
                    incur no liability to the User for any act, done or omitted
                    to be done, in good faith and with bona fide intentions, by
                    reason of:
                    <ol
                      type="lower-alpha"
                      style={{ listStyleType: "lower-alpha" }}
                    >
                      <li style={{ marginTop: "1%" }}>
                        any provision of any Applicable Law; or
                      </li>
                      <li style={{ marginTop: "1%" }}>
                        any decree, order or judgment of any court;
                      </li>
                      <li style={{ marginTop: "1%" }}>
                        it is becoming impossible or impracticable for Bridgit
                        to carry out any of the provisions of this Agreement.
                      </li>
                    </ol>
                  </li>
                </li>
                <li style={{ marginTop: "1%" }}>
                  You agree that this undertaking shall be valid until such time
                  You have not expressly informed in writing that you do not
                  wish to deal or transact any longer and it shall be also valid
                  in respect of any claims made on account of or relating to
                  Your dealing with Bridgit.
                </li>
                <li style={{ marginTop: "1%" }}>
                  This Section and the indemnities given hereunder shall survive
                  the termination of this Agreement.
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            9. DISCLAIMER AND DISCLOSURES
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  Product investments are subject to market risks, read all
                  Product Related Documents carefully before investing through
                  the Platform or using the Services.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The data, reports and information provided on the Platform do
                  not constitute professional advice and therefore should not be
                  relied upon while taking investment decisions from a
                  professional context. You are therefore advised to obtain
                  applicable professional advice, including, legal, accounting,
                  tax or other professional advice or services, before taking an
                  investment decision.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Your access to and use of the Platform and/or the Services or
                  any content are at Your own risk. You understand and agree
                  that the Platform and/or the Services are provided to You on
                  an “AS IS” and “AS AVAILABLE” basis. Without limiting the
                  foregoing, to the maximum extent permitted under applicable
                  law, Bridgit DISCLAIMS ALL WARRANTIES AND CONDITIONS, WHETHER
                  EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Without limiting the
                  generality of the foregoing, We do not warrant that the
                  Services provided by Us will be free from bugs, defects or
                  errors, or accessible without interruption.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The investment decision, if any, will be taken by You in Your
                  sole and absolute discretion without any further liability to
                  Bridgit.
                </li>
                <li style={{ marginTop: "1%" }}>
                  All benefits on investments will be taxed as per the
                  provisions of Income Tax Act 1961, as amended from time to
                  time.
                </li>
                <li style={{ marginTop: "1%" }}>
                  This service does not constitute an offer to buy or to sell or
                  a solicitation to any person in any jurisdiction where it is
                  unlawful to make such an offer or solicitation.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit / its authorised representatives /service providers
                  are not responsible for any omissions, errors or investment
                  consequences arising from the use of the materials available
                  on the Platform.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Whilst every reasonable precaution has been taken to ensure
                  the accuracy, security and confidentiality of data and
                  information available on the Platform, Bridgit /its authorised
                  representatives /service providers shall not be held
                  responsible for any consequence of any action carried out by
                  You.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The services/facility may be suspended or withdrawn at any
                  time, and You acknowledge that Your account is also subject to
                  interruption.
                </li>
                <li style={{ marginTop: "1%" }}>
                  You acknowledge and agree that the Platform, the Services and
                  the linked backend infrastructure may be inaccessible and/or
                  unavailable at times. In such an event Bridgit will not be
                  liable for any damage, liability, action, cause of action,
                  suit, claim, demand, loss, cost, and expense arising directly
                  or indirectly from or in connection with such system
                  inaccessibility and/or unavailability.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit /its authorised representatives /service providers
                  shall not be liable for any loss or damage or other
                  consequences arising from any suspension, breakdown,
                  withdrawal, interruption, technical flaw, the presence of
                  virus or other malicious, destructive or corrupting code,
                  programs on the Platform or otherwise and any consequent delay
                  or failure in completion of any payment or other instructions
                  as a consequence thereof arising from the use or inability to
                  use the Platform.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit /its authorised representatives /service providers
                  shall not be liable for any failure to perform any of its
                  obligations or service standards etc. if the performance is
                  prevented, hindered or delayed by a Force Majeure Event, which
                  includes any event due to any cause beyond the reasonable
                  control including, without limitation, unavailability of any
                  communication system including Internet, breach or virus in
                  the processes or payment mechanism, sabotage, pandemic, fire,
                  flood, explosion, acts of God, civil commotion, strikes or
                  industrial action of any kind, riots, insurrection, war, acts
                  of government, computer hacking, unauthorised access to
                  computer data and storage devices, computer crashes, breach of
                  security and encryption codes.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The investment profile of the User is prepared by Bridgit and
                  is based on Bridgit’s proprietary algorithms for understanding
                  and assessing risk and suitability in line with the objectives
                  and requirements of the User, as per the details/information
                  provided by the User. Bridgit may use the information provided
                  herein for the purposes of undertaking an internal risk
                  assessment of the User. A series of questions may help Bridgit
                  to appraise the User’s risk profile, particular needs,
                  financial situation, investment objectives and investment in
                  financial instruments. While undertaking the process of risk
                  profiling or determining product suitability, Bridgit may make
                  certain assumptions and use certain industry accepted norms
                  and parameters. It may be noted that in case certain other
                  parameters or methods are used, the risk profiling may lead to
                  a different result. Hence, for instance, it may not be
                  possible to state that the outcome of the risk assessment is
                  absolutely precise and will be the same under all
                  circumstances. Please note that the questions are only meant
                  to be a guide to help determine the User’s profile and shall
                  not be considered to be conclusive. The User is required to
                  ensure that the User has provided accurate and complete
                  information. The User is aware, acknowledges and agrees that
                  if the User provides inaccurate and/or incomplete information,
                  and/or chooses not to disclose certain information, such
                  action will adversely affect the results of the risk
                  assessment. Bridgit may provide information in relation to the
                  purchase/sale of financial instruments on the basis of such
                  internal risk assessment. However, this risk assessment is for
                  internal purposes alone and advice prepared for the User is
                  based on the information as shared by the User and in
                  accordance with the risk profiling and suitability measures as
                  undertaken by Bridgit. The User is not bound to act on the
                  investment profile prepared by Bridgit and in the event the
                  User acts based on the advice or information provided by
                  Bridgit, Bridgit shall not be responsible for any consequences
                  that may ensue. Any suggested investment is based on the
                  information provided by the User and is only one of the many
                  combinations of investments that may be suggested to someone
                  with the User’s risk profile. The investment advice provided
                  by Bridgit to the User is as per what Bridgit deems fit to be
                  appropriate for the User, based on the data shared by the User
                  and the risk assessment done by Bridgit. Further, please note
                  that while Bridgit will take into account Your investment
                  objectives and financial goals as shared by You and recommend
                  investments in line with Your risk appetite, there may be
                  other clients of Bridgit, who may be provided with similar
                  investment profiles, especially in light of the algorithm
                  software and technology being utilised by Bridgit for
                  rendering Investment Advisory Services. User will not solely
                  rely on the views, representations (whether written or oral or
                  otherwise), recommendations, opinions, reports, analysis,
                  information or other statement made by Bridgit or any of its
                  agents, directors, officers or employees and that the User
                  will make the User’s own assessment and rely on the User’s own
                  judgment in making any investment decision. In the event that
                  the User seeks to make an investment pursuant to the
                  information or recommendation provided by Bridgit, the User
                  shall do so at its/his/her own risk, and should consider
                  its/his/her financial situation, objectives and needs and
                  consult with its/his/hers legal, business, tax and/or other
                  professional advisors to determine the appropriateness and
                  consequences of such an investment. The User may, at
                  his/her/its own discretion, opt not to follow the investment /
                  product / recommendations provided by Bridgit, irrespective of
                  whether they are in line with the objectives / requirements
                  specified in this document or the assessment derived from the
                  details provided herein.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit does not assure or guarantee any returns in relation
                  to the investment profile it may generate. Bridgit and/or any
                  of its subsidiaries/group companies/agents/vendors along with
                  all their directors/officers/employees and agents shall not be
                  responsible for the amount / value of profit earned / loss
                  incurred by the User or its variance from the mean / any other
                  ratio used to evaluate the returns from the mutual funds/
                  market / alternate products / portfolios, that the User may
                  have earned. Third party investment products are not
                  obligations of or guaranteed by Bridgit or any of its
                  affiliates or subsidiaries and are subject to investment risks
                  and foreign exchange risks, including the possible loss of the
                  principal amount invested.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User shall notify Bridgit of any material change in
                  its/his/her situation and/or profile and Bridgit is entitled
                  to rely on the most recent information provided by the User.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User who subscribes for any services on the Platform
                  agrees that the Service Fees and/Investment Advisory Service
                  fees (including taxes etc.) paid may be only partially
                  refundable including in case of deactivation, unsubscribing
                  the Services or in any other circumstances, subject to
                  Applicable Laws, including the Regulations.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User agrees that in case of any change in the User’s email
                  address and/or mobile number, the User shall inform Bridgit in
                  advance in the prescribed format to carry out the necessary
                  changes in its records. The User confirms that the
                  information/details provided by the User is true, accurate,
                  correct and complete.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The User agrees to be contacted by Bridgit and its
                  representatives over phone or on registered email address with
                  reference to the Services. The User agrees and confirm that if
                  the User’s mobile number is registered in the Do Not Disturb
                  (DND) list of TRAI, the User may not receive SMS from us. The
                  User shall take steps to deregister from the DND list and
                  shall not hold Us liable for non-receipt of SMS in the interim
                  period.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The Platform shall also have links to third-party websites,
                  Products and/ or services. Access or usage of such third-party
                  links will be governed by the respective third-party terms and
                  conditions at all times.
                </li>
                <li style={{ marginTop: "1%" }}>
                  You understand and accept that Bridgit is not responsible for
                  the availability of content or other services on third party
                  sites linked from the application. You are aware that access
                  of hyperlinks to other internet sites are at Your own risk and
                  the content, accuracy, opinions expressed, and other links
                  provided by these sites are not verified, monitored or
                  endorsed by Bridgit in any way. Bridgit does not make any
                  warranties and expressly disclaims all warranties express or
                  implied, including without limitation, those of
                  merchantability and fitness for a particular purpose, title or
                  non-infringement with respect to any information or services
                  or Products that are available or advertised or sold through
                  these third-party platforms.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The Website and/or App contains information, statements,
                  calculators, opinions, statistics, pProducts, services, and
                  materials (“Content”) that have been obtained from sources
                  believed to be reliable and an effort has been made to provide
                  accurate information, however Bridgit make no guarantees
                  whatsoever regarding the timeliness, completeness, accuracy,
                  adequacy, fullness, functionality or reliability of the
                  cContent contained, provided and posted on any section of the
                  Website or of the results obtained from the use of such
                  cContent and make no warranties of any kind and nature
                  whatsoever either express or implied, including but not
                  limited to warranties regarding the accuracy, performance,
                  merchantability and/or fitness for a particular purpose of the
                  cContent contained, provided and posted on the Website or of
                  the results obtained from the use of such Content. The use of
                  any information set out is entirely at the User’s own risk
                </li>
                <li style={{ marginTop: "1%" }}>
                  The output of the net worth chart which may be referred as
                  Wealth Chart or GoalLine, or any other such term, is
                  completely and solely based on the information/details
                  provided by YouYou in response to the questions specified by
                  Bridgit, other than the assumptions made by Bridgit regarding
                  returns, asset allocation, inflation, growth rates of various
                  parameters etc. These questions and the calculations thereon
                  resulting in specific data are developed and based on certain
                  tools and calculators that are based on
                  presumptions/assumptions. These assumptions and calculations
                  may be subject to errors and omissions and Bridgit make no
                  guarantees, whatsoever, regarding the timeliness,
                  completeness, accuracy, adequacy, fullness, functionality or
                  reliability of the results so obtained
                </li>
                <li style={{ marginTop: "1%" }}>
                  While providing and developing these tools, Bridgit has relied
                  upon and assumed, without independent verification, the
                  accuracy and completeness of all information made available to
                  it from public / private sources and vendors or which has
                  otherwise been reviewed by Bridgit. Such information and the
                  resultant data is/are provided only for User’s convenience and
                  information purposes.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit does not undertake any liability or responsibility to
                  update any data or communicate to You in case any data is
                  updated.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The Content generated herein may be subject to change,
                  updation, revision, verification and amendment without notice
                  and such information/data generated may change materially.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The Content of this Website is prepared by Bridgit for general
                  purpose information only and should not be construed as an
                  offer or solicitation any Product(s). It does not consider the
                  investment objectives, financial situation or particular needs
                  of the User of the tool(s).
                </li>
                <li style={{ marginTop: "1%" }}>
                  All Products are subject to variety of risks (market,
                  interest, credit etc) and please read all Product Related
                  dDocuments carefully. The value of Products may go up or down
                  depending upon the factors and forces affecting the markets of
                  such Products, the general economic conditions, default or
                  credit risk and many such other risk factors which affect the
                  values of such Products and schemes. The past performance of
                  the Products and schemes is not necessarily indicative of
                  their future performance. You must go through the Product
                  Related Documents to understand all the risks related to such
                  Product investments. The Products may have features including
                  but not limited to lock-in of investment, exit loads, first
                  loss, etc and the Product Related Documents gives a detail
                  description of such features.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The output provided to You shouldn’t be construed as an advice
                  provided by Bridgit in any way or form. Bridgit provides
                  advice only after entering into advisory agreements with its
                  clients and completing all other legal and regulatory
                  requirements.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Users who have an advisory agreement with Bridgit (“Advisory
                  Users”) would be guided by the agreement signed with them in
                  addition to these Terms of Use and others terms and conditions
                  in the Website.
                </li>
                <li style={{ marginTop: "1%" }}>
                  The data and information provided by You or downloaded or
                  uploaded by You using various mediums provided in the Website
                  will be stored securely by Bridgit. The storage of such
                  information and data would be guided by the internal policies
                  including Privacy Policy of Bridgit as well as the applicable
                  rules and regulations. Saving & storage of data and
                  information downloaded by You from third parties would
                  additionally be guided by Bridgit’s agreement with such third
                  parties and the terms & conditions applicable there.
                </li>
                <li style={{ marginTop: "1%" }}>
                  While providing information to Bridgit, You shall input login
                  credentials of various accounts and statements including but
                  not limited Your broking accounts, eCAS statement, Account
                  Aggregator login account, Employees' Provident Fund
                  Organisation member login account, Income Tax login account.
                  By proceeding ahead with that login, You grant Bridgit
                  permission to fetch all relevant data from such sources and
                  save the data for current and future use. Bridgit doesn’t save
                  such usernames, passwords, / PINs or such other secure
                  information of any of Your accounts and statements. If We
                  intend to save any of such passwords, We shall do that only
                  after taking an explicit approval from You for that specific
                  account.
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            10. LIMITATION OF LIABILITY
            <div className="tos-subtext-paragraph">
              You acknowledge and agree that, in no event will We be liable to
              You or any third party for any indirect, punitive, exemplary,
              incidental, special, or consequential damages whether in contract,
              tort (including negligence), or otherwise arising out of this
              Agreement, or the use of, or the inability to use the App, Website
              and the Platform, including, without limitation, any information
              made available through the Platform pursuant to this Agreement. In
              the event the foregoing limitation of liability is determined by a
              court of competent jurisdiction to be unenforceable, then the
              maximum liability for all claims of every kind will not exceed one
              times (1x) the aggregate of payments received from You by Us under
              this Agreement. To the extent that We may not disclaim any implied
              warranty or limit its liabilities, the scope and duration of such
              warranty and the extent of Our liability will be the minimum
              permitted under applicable law.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            11. TERMINATION
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  The Platform and the Services may, at any time, be suspended /
                  disallowed /modified for such reason and for such time period
                  as deem fit like inactive / dormant user id, noncompliance of
                  the terms stated herein, death, insolvency, bankruptcy of the
                  investor, any other cause arising out of the operation of law
                  or such other reason as deems proper.
                </li>
                <li style={{ marginTop: "1%" }}>
                  You may terminate this binding legal Agreement with Bridgit at
                  any time by providing fifteen (15) days’ prior written notice
                  or deactivating Your account, user id and password, with a
                  possible termination charge from Bridgit
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit reserves the right to suspend or terminate Your
                  account or cease providing You with access to all or part of
                  the Platform at any time for any or no reason by providing
                  thirty (30) days’ notice by way of SMS to Your registered
                  phone number or Your registered email ID. The reasons for such
                  suspension or termination of Your use of the Platform may
                  include, but not limited to, if Bridgit reasonably believes:
                  (i) You have violated this Agreement or the Platform’s Privacy
                  Policy, (ii) You create risk or possible legal exposure to us;
                  or (iii) Bridgit’s provision of the Platform and Services to
                  You is no longer commercially viable. In all such cases, this
                  Agreement shall terminate, including, without limitation, Your
                  license to use the App, the Platform and Services thereunder.
                  In the event of termination for any reason whatsoever, You
                  shall be liable to pay all outstanding charges, fees and dues,
                  if any.
                </li>
                <li style={{ marginTop: "1%" }}>
                  In case You cease to be a user of Bridgit, then all Your
                  details will be provided to the mutual fund/AMCs/Product
                  Providers and as are required by them to enable you to deal
                  and interact directly with the AMCs/mutual fund.
                </li>
                <li style={{ marginTop: "1%" }}>
                  In the event of termination of this Agreement in accordance
                  with Section 11, no portion of the Investment Advisory
                  Services fees or Service Fees made to Bridgit shall be owed
                  back or be repayable to the User, subject to Applicable Laws,
                  including the Regulations.
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            12. COPYRIGHT INFRINGEMENT
            <div className="tos-subtext-paragraph">
              <ol type="lower-roman" style={{ listStyleType: "lower-roman" }}>
                <li style={{ marginTop: "1%" }}>
                  If You believe that any content on the Platform violates Your
                  copyright, and You wish to have the allegedly infringing
                  material removed, the following information in the form of a
                  written notification must be provided to Our designated agent:
                  <ol
                    type="lower-alpha"
                    style={{ listStyleType: "lower-alpha" }}
                  >
                    <li style={{ marginTop: "1%" }}>
                      Your physical or electronic signature;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Identification of the copyrighted work(s) that You claim
                      to have been infringed;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Identification of the material on the App, Website and/or
                      the Platform that You claim is infringing and that You
                      request Bridgit to remove;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Sufficient information to permit Bridgit to locate such
                      infringing material
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      Your address, telephone number, and email address;
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      A statement that You have a good faith belief that use of
                      the objectionable material is not authorised by the
                      copyright owner, its agent, or under the law; and
                    </li>
                    <li style={{ marginTop: "1%" }}>
                      A statement that the information in the notification is
                      accurate, and under penalty of perjury, that You are
                      either the owner of the copyright that has allegedly been
                      infringed or that You are authorised to act on behalf of
                      the copyright owner.
                    </li>
                  </ol>
                </li>
                <li style={{ marginTop: "1%" }}>
                  Bridgit’s designated Copyright Agent to receive notices
                  pursuant to this Section, at Bridgit, Attn: Infringement
                  Notice, [support@bridgit.club]. You acknowledge that for
                  Bridgit to be authorised to take down any content, Your
                  takedown notice must comply with all the requirements of this
                  Section 12.
                </li>
                <li style={{ marginTop: "1%" }}>
                  Please note that any misrepresentation of material fact
                  (falsities) in a written notification automatically subjects
                  the complaining party to liability for any damages, costs and
                  attorney’s fees incurred by Bridgit in connection with the
                  written notification and allegation of copyright infringement
                </li>
              </ol>
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            13. ASSIGNMENT
            <div className="tos-subtext-paragraph">
              This Agreement is only for Your benefit as an individual. You
              shall have no right to assign this Agreement or any benefits or
              obligation hereunder to any other party or legal entity, including
              in a commercial capacity. Any attempted assignment shall be void.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            14. NOTICES
            <div className="tos-subtext-paragraph">
              Bridgit may send promotional, newsletters, offer messages about Us
              and Our products related to the Platform and/or Services to Your
              email. When You send Bridgit a query email at
              support@bridgit.club, You are providing Bridgit with consent to
              send emails to You for replying to Your queries at Your provided
              email address. By providing Your email address, You agree and
              consent to receiving such notices from Bridgit, subject to the
              terms of this Terms of Service and the Platform’s Privacy Policy.
              You can opt out of receiving push notifications through Your
              device settings. Please note that opting out of receiving push
              notifications may impact Your use of the Platform. Except as
              explicitly stated otherwise, any notices to Us shall be given by
              certified mail, postage prepaid and return receipt requested to us
              at [support@bridgit.club]. Any notices to You shall be provided to
              You through the App, Website and/or the Platform or given to You
              via the email address or physical address You provide to Bridgit
              during the registration process
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            15. MODIFICATIONS
            <div className="tos-subtext-paragraph">
              Bridgit reserves the right, at Our sole discretion, to change or
              modify this Agreement at any time. In the event, Bridgit modifies
              the terms of this Agreement, such modifications shall be binding
              on You from the time such changes are notified to You by Bridgit
              via email or on the App, Website or the Platform by posting a
              modified version of this page, or by a comparable means within a
              reasonable time period. Your continued use of the App,-and/or,
              Website and/or the Platform shall constitute Your consent to such
              changes. These terms and conditions of the Agreement may be
              amended for such reason as is deemed fit and proper, including but
              not limited to, comply with changes in law or regulation, correct
              inaccuracies, omissions, errors or ambiguities, reflect changes in
              the process flow, scope and nature of the services, company
              reorganisation or investor requirements.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            16. RELATIONSHIP
            <div className="tos-subtext-paragraph">
              The parties (User and Bridgit) hereto are independent contractors,
              and nothing contained herein shall be interpreted as creating any
              relationship other than that of independent contractors. The
              parties shall not be construed as being partners, joint ventures,
              shareholders, employer/employee, or agent/servant. The User has no
              power or authority to bind Bridgit to any obligation, agreement,
              debt or liability. The User shall not hold itself out as an agent
              or representative of Bridgit.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            17. GOVERNING LAW & DISPUTE RESOLUTION
            <div className="tos-subtext-paragraph">
              Any Dispute that may arise in respect of Your use (directly or
              indirectly) of Our Platform shall be governed by all laws
              applicable within the territory of India. You consent and agree
              that any and all disputes with respect to this Privacy Policy, Our
              Services, and the Platform (collectively “Disputes,” shall be
              resolved through mandatory, binding arbitration, which shall be
              governed under the Indian Arbitration and Conciliation Act, 1996.
              As such, You agree to waive Your respective right to have any and
              all Disputes arising from or related to this Privacy Policy,
              and/or Your use of Our Services or Platform, resolved in a
              competent court. Instead, You and We agree to arbitrate Disputes
              through binding arbitration (which is the referral of a Dispute to
              one or more persons charged with reviewing the Dispute and making
              a final and binding determination to resolve it instead of having
              the Dispute decided by a judge in court). We agree that the seat
              of jurisdiction of such arbitration for the Dispute will be held
              in Delhi, India
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            18. MISCELLANEOUS
            <div className="tos-subtext-paragraph">
              This Agreement along with the Platform’s Privacy Policy,
              constitute the entire agreement between You and Us and supersedes
              any prior agreements between You and us with respect to the
              subject matter herein. Our failure to exercise or enforce any
              right or provision of this Agreement will not constitute a waiver
              of such right or provision. If any provision of this Agreement is
              found by a court of competent jurisdiction or arbitrator to be
              invalid, We both nevertheless agree that the court/arbitrator
              should endeavour to give effect to Our intentions as reflected in
              such provision, and the other provisions of this Agreement to
              remain in full force and effect. You agree that regardless of any
              statute or law to the contrary, any claim or cause of action
              arising out of or related to use the App, Website and the Platform
              or this Agreement will be subject to the applicable statute of
              limitations. A party’s failure to act with respect to a breach by
              the other party does not constitute a waiver of the party’s right
              to act with respect to subsequent or similar breaches. All the
              sections intended to survive the termination of this Agreement
              shall survive. The section titles in this Agreement are for
              convenience and description only and have no legal or contractual
              effect.
            </div>
          </div>
          <div className="tos-subbtext-heading" style={{ marginTop: "3%" }}>
            19. GRIEVANCE REDRESSAL
            <div className="tos-subtext-paragraph">
              If You have any questions, concerns, or suggestions regarding this
              Terms of Service, please reach out to Us using the contact
              information on Our “Notices” section. If You have any grievance or
              complaint with respect to the Platform and the Services, please
              reach out to the grievance officer, the name and contact details
              provided below: E-mail: support@bridgit.club We will acknowledge
              Your complaint within a period of 36 (thirty) hours and share the
              complaint registration number to enable You to track the status of
              the complaint for future correspondence. The grievance office
              shall investigate the complaint and shall remove any violative
              content, if any, and shall resolve the matter within a period of 1
              (one) month. The grievance officer shall provide You with a
              summary of the resolution of the compliant after this process.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
