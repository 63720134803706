import React, { useState } from "react";
import { PopupModal } from "react-calendly";
const TaxPricing = () => {
  const bookTrialClicked = () => {
    setCalendlyOpen(true);
  };
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const ITRFilingCalendly =
    "https://calendly.com/bridgit-financial-advisors/itr-filing";
  return (
    <>
      <script>fbq('track', 'Schedule');</script>
      <h4 className="pricing-page-plans-intro">
        Experience Hassle-Free Assited ITR Filing at an Unbeatable Price.
        <br></br> Schedule a Call with Our CAs, Choose a Suitable Plan, Share
        Your Documents Securely via WhatsApp Hotline, and Watch Your Taxes Get
        Filed within Minutes.
      </h4>
      <a
        className="pricing-page-plans-checklist"
        href="https://forms.zohopublic.in/bridgitfinancialadvisorspvtltd/form/Signup/formperma/80LDnbnpE_Y0BuFvNcX-ThqO25cUc1FqH9WkOKWaN_s"
        title="Download Your ITR Checklist!"
        target="_blank"
        onclick="zforms_open_window(this.href, 648, 700); return false"
      >
        <h4 className="pricing-page-plans-checklist-text">
          Here’s a compiled checklist of ITR filing documents for hassle free
          returns.
        </h4>
        <div className="pricing-page-plans-checklist-download">
          <h4 className="pricing-page-plans-checklist-download">
            Download Checklist
          </h4>
        </div>
      </a>
      <div className="pricing-page-plans" id="itr-tab-click">
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Zero Tax Filing</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Form 16</li>
              <li>&bull; Income from Other Sources</li>
              <li>&bull; Agriculture Income</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 799/-
                <span className="pricing-page-price-span">
                  {" (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="itr-pricing-zerotax"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Salaried Professional</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Multiple Form 16</li>
              <li>&bull; Single & Multiple House Property</li>
              <li>&bull; Income from Other Sources</li>
              <li>&bull; Agriculture Income</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 1199/-
                <span className="pricing-page-price-span">
                  {" (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="itr-pricing-salaried-professional"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">
              Business & Professional
            </h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single & Multiple Employers</li>
              <li>&bull; Business & Professional Income</li>
              <li>&bull; Single & Multiple House Property</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 1999/-
                <span className="pricing-page-price-span">
                  {" (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="itr-pricing-business-professional"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Capital Gains</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single & Multiple Employers</li>
              <li>&bull; Single & Multiple House Property</li>
              <li>&bull; Multiple Capital Gain Income</li>
              <li>&bull; Business & Professional Income</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 2799/-
                <span className="pricing-page-price-span">
                  {" (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="itr-pricing-cpaital-gains"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">NRI/Foreign Income</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Foreign salary(Foreign Tax relief)</li>
              <li>&bull; DTAA Tax Relief</li>
              <li>&bull; Multiple Capital Gain Income</li>
              <li>&bull; RSU/ESOPs</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 7999/-
                <span className="pricing-page-price-span">
                  {" (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="itr-pricing-nri-foreign"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <PopupModal
          url={ITRFilingCalendly}
          //   pageSettings={this.props.pageSettings}
          //   utm={this.props.utm}
          //   prefill={this.props.prefill}
          onModalClose={() => setCalendlyOpen(false)}
          open={calendlyOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    </>
  );
};

export default TaxPricing;
