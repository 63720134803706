import React from "react";

const BlogTheme = ({ blogTheme, onClick, isSelected }) => {
  return (
    <h3
      className={"themes" + (isSelected ? "-selected" : "")}
      onClick={onClick}
    >
      {blogTheme}
    </h3>
  );
};

export default BlogTheme;
