/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "./mobileScroll.css";
import ScreenText from "./ScreenText";

const scrollData = [
  {
    heading: " We take a comprehensive look at your ",
    span2: "Financial Situation",
    span: "",
    mobile_img: require("../../images/financial-snapshot.png"),
    alt: "bridgit-financial-advisors",
  },
  {
    heading: "and build a specific financial plan to reach ",
    span2: " Your Goals",
    span: "",
    mobile_img: require("../../images/investment.png"),
    alt: "bridgit-goal-planning",
  },
  {
    heading: "Because it's not just a plan for your money, it's a plan for ",
    span2: "Your Life!",
    span: "",
    mobile_img: require("../../images/portfolio-performance.png"),
    alt: "bridgit-portfolio-planning",
  },
  {
    heading: "You can now feel confident about going for your ",
    span2: "Short-Term ",
    span: "goals",
    mobile_img: require("../../images/goal-planning.png"),
    alt: "bridgit-complete-planning",
  },
  {
    heading: "knowing you're making progress on your ",
    span2: "Long-Term ",
    span: "ones",
    mobile_img: require("../../images/retirement.png"),
    alt: "bridgit-retirement-planning",
  },
];
const PlanForEverything = () => {
  const [currentImg, setCurrentImg] = useState(0);
  return (
    <div className="section-4-what">
      <div className="mobile-scroll flex max-width">
        <div className="scroll-full-screen-wrapper">
          {scrollData.map((screen, i) => (
            <div className="scroll-full-screen">
              <ScreenText screen={screen} setCurrentImg={setCurrentImg} i={i} />
            </div>
          ))}
        </div>
        <div className="mobile-mockup-wrapper non-mobile">
          <div className="mobile-mockup ">
            <div className="mobile-mockup-screen flex absolute-center">
              <img
                src={scrollData[currentImg].mobile_img}
                className="mobile-screen-img slide-in-right "
                key={scrollData[currentImg].mobile_img}
                alt="bridgit-financial-advisors"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanForEverything;
