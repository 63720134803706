import React, { useState } from "react";
import { PopupModal } from "react-calendly";
const FPSPricing = () => {
  const bookTrialClicked = () => {
    setCalendlyOpen(true);
  };
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const ITRFilingCalendly =
    "https://calendly.com/bridgit-financial-advisors/financial-planning";
  return (
    <>
      <div className="pricing-page-plans-intro">
        Secure Your Financial Future with a Dedicated Financial Advisor at an
        Unbeatable Price.
        <br></br>Schedule a call with our financial advisor, Choose a Suitable
        Plan, Get our Expertly Curated Investment Portfolios that Ensure Maximum
        Returns and Keep You on Track to Achieve Your Goals.
      </div>
      <div className="pricing-page-plans-checklist">
        <div className="pricing-page-plans-checklist-download">
          <div className="pricing-page-plans-checklist-download">
            Transparent. Fair. Fixed fee of 0.1%-0.2% of your net income. No
            hidden charges.
          </div>
        </div>
      </div>
      <div className="pricing-page-plans" id="fps-tab-click">
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading"> Premium Single</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Income: (₹3L - ₹10L) p.a</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Two 1:1 sessions with advisor</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 999
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="fps-picing-premium-single"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Exclusive Single</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Income: (₹10L - ₹20L) p.a</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 1999
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="fps-picing-exclusive-single"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Signature Single</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Income: (₹20L - ₹35L) p.a</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 3499
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="fps-picing-signature-single"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Family Elite</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Family Income: (₹15L - ₹25L) p.a</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 2699
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="fps-picing-family-elite"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Family Select</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Family Income: (₹25L - ₹35L) p.a</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 4199
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="fps-picing-family-select"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container">
            <h3 className="pricing-page-info-heading">Tailored</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Income: ₹35L p.a+</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">Custom Pricing</h4>
            </div>
            <button
              id="fps-picing-tailored"
              className="pricing-page-schedulepay-button"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <PopupModal
          url={ITRFilingCalendly}
          //   pageSettings={this.props.pageSettings}
          //   utm={this.props.utm}
          //   prefill={this.props.prefill}
          onModalClose={() => setCalendlyOpen(false)}
          open={calendlyOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    </>
  );
};

export default FPSPricing;
