import React from "react";
import "./statSection.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const StatSection = () => {
  return (
    <div className="section-2-stats">
      <div className="stat-wrapper">
        <div className="session-count">
          <h2 className="count inter-bold-sunglow">
            <CountUp end={145} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </h2>
          <h3 className="label-heading-1 inter-semi-bold-white">
            Bridgit Financial Planning Sessions
          </h3>
          <h4 className="label-text">in last 3 months</h4>
        </div>
        <div className="asset-count">
          <h2 className="count-1 inter-bold-sunglow">
            <span className="inter-bold-sunglow">
              ₹
              <CountUp start={0} end={11} decimals={1} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              +
            </span>
            <span className="span1"> crore</span>
          </h2>
          <h3 className="label-heading-2 inter-semi-bold-white">
            Worth Of Assets Advised
          </h3>
        </div>
      </div>
    </div>
  );
};

export default StatSection;
