import React, { useState } from "react";
import "./footer.css";
import logo from "../../images/logo-Bridgit.svg";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { PopupModal } from "react-calendly";
import config from "../../shared/config";

const FooterSection = () => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onLinkClick = (url) => {
    window.open(url, "_self", "noopener,noreferrer");
  };

  return (
    <div className="footer-section">
      <div className="footers-section-wrapper">
        <div className="footer-container-left">
          <div className="footer-logo">
            <img src={logo} alt="bridgit-financial-advisors" />
          </div>

          <div className="footer-address">
            <div className="footer-quote-text">
              Wealth is a plan, not an amount. We are here to implement that
              plan.
            </div>
          </div>
          <button
            className="cta-primary-footer"
            id="footer-book-session"
            onClick={() => setCalendlyOpen(true)}
          >
            <div className="cta-text-footer">Book a free trial</div>
          </button>
          <PopupModal
            url="https://calendly.com/bridgit-financial-advisors/financial-planning"
            //   pageSettings={this.props.pageSettings}
            //   utm={this.props.utm}
            //   prefill={this.props.prefill}
            onModalClose={() => setCalendlyOpen(false)}
            open={calendlyOpen}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
          />
          <div className="footer-social-media-links">
            <div
              className="footer-social"
              onClick={() => openInNewTab("https://wa.me/919319096509")}
            >
              <FaWhatsapp color="white" fontSize="1.8em" />
            </div>
            <div
              className="footer-social"
              onClick={() =>
                openInNewTab(
                  "https://www.instagram.com/bridgitfinancialadvisors"
                )
              }
            >
              <FaInstagram color="white" fontSize="1.8em" />
            </div>
          </div>
        </div>
        <div className="footer-container-right">
          <div className="footer-links-container">
            <div className="footer-title"> Bridgit</div>

            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(config.get(process.env.NODE_ENV)["WEB_URL"])
              }
            >
              Homepage
            </div>
            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "#testimonial"
                )
              }
            >
              Testimonials
            </div>

            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/pricing"
                )
              }
            >
              Pricing
            </div>
            <a href="https://wa.me/919319096509" className="footer-link">
              Contact Us
            </a>
          </div>
          <div className="footer-links-container">
            <div className="footer-title"> Explore</div>
            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "#faq"
                )
              }
            >
              FAQs
            </div>
            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/blogs"
                )
              }
            >
              Blogs
            </div>
          </div>
          <div className="footer-links-container">
            <div className="footer-title"> Legal</div>
            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] +
                    "/termsofservice"
                )
              }
            >
              Terms of Service
            </div>
            <div
              className="footer-link"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/privacypolicy"
                )
              }
            >
              Privacy Policy
            </div>
          </div>
        </div>
      </div>
      <div className="divider-footer"></div>
      <div className="footer-base">
        <div className="footer-base-text">
          Bridgit © {new Date().getFullYear()} All rights reserved.
        </div>
        <div className="footer-address-text">
          Bridgit Financial Advisors Private Limited <br></br>CIN:
          U67110DL2022PTC403767 <br></br>B-13, Basement Moti Nagar, Central
          Delhi, DL 110015
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
