import "./faqsection.css";
import React, { useState } from "react";
import FAQ from "./faq";

const FAQSection = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "Do I Need A Financial Advisor Or Should I Do It Alone?",
      answer:
        "Ultimately that's for you to decide - But there are clear advantages to working with a financial professional:" +
        "\n1. Your advisor saves you time - They can research investment options and monitor your investment performance, so you don't have to." +
        "\n2. Your advisor is an expert - Depending on your investing expertise, you may see better investment results working with an advisor than by managing money alone." +
        "\n3. Your advisor can keep you from making expensive, emotional decisions - Emotion can be an investor's worst enemy. Putting an advisor between you and your money can create the space you need to stay patient when the market gets volatile.",
      open: true,
    },
    {
      question: "What to look for in a financial advisor?",
      answer:
        "Best Financial Advisor is the one who's financially " +
        "savvy, of course, but also personable and trustworthy. " +
        "Trusting someone with your money is equivalent to trusting " +
        "someone with your life and we understand that. Financial advisor " +
        "should have a heart of a teacher. They should be willing to not only " +
        "recommend you but empower you to take the right financial decisions. " +
        "You work as a team with your advisor while taking financial decisions.",
      open: false,
    },
    {
      question:
        "Should I have decent capital before I start building a financial plan?",
      answer:
        "Well, if you can save at least ₹1,000 monthly, we can build a financial plan for you. The important thing is to start building wealth; we can constantly adjust the amount we save and invest based on our goals, personal financial situation, etc.",
      open: false,
    },
    {
      question:
        "What is the average price of a financial planning and advisory service. Why is Bridgit giving it for ₹999?",
      answer:
        "There are typically two ways financial advisors can charge you money in India based on SEBI guidelines: \n 1. One-Time Fee - Here, the financial advisor will charge a one-time fee for the service. In India, that ranges anywhere from ₹500 to ₹5,000 per month but cannot exceed ₹75,000(per annum) as per SEBI guidelines. \n 2. Commission-Based - Here, the financial advisor will charge based on the amount of assets it advises you on. Say they are advising you on ₹10,00,000. As per SEBI guidelines, they can charge up to 2.5% of that amount, i.e., ₹25,000. \n \n At Bridgit, we want to democratize financial planning and advisory. Today this service is accessible to only those who can afford to pay tens of thousands of rupees. We want to change that and make it accessible and affordable to everyone. ",
      open: false,
    },
    {
      question: "Do you need a certain net worth to work with an advisor",
      answer:
        "No. Some advisors enforce net worth thresholds, but Bridgit wants to change that. We want to keep the service affordable for everyone. Wealth is a plan, not an amount.",
      open: false,
    },
    {
      question: "What qualifications and experience does the advisors have?",
      answer:
        "Bidgit advisors are finance professionals with CFA and CFP degrees with 5+ years of experience in the industry. Along with excellent qualifications, our advisors understand the importance of managing and advising people on their money. Our advisors ensure they understand your background and make a personalized plan for you.",
      open: false,
    },
    {
      question: "Who do you provide financial planning for?",
      answer:
        "Well, glad you asked! \n If you are entirely new to the world of saving and investing and want to get started, or you are someone who has already begun building a portfolio, bridgit caters to everyone. It doesn’t matter how much you earn and how much you are willing to save and invest. All that matters is that you are eager to build wealth and have financial freedom because wealth is a plan, not an amount, and we are here to implement that plan!",

      open: false,
    },
    {
      question: "Final verdict, is it for me or no?",
      answer:
        "Here's what it comes down to if you have money to invest and financial goals to pursue but need a definitive plan, it may be time to retain an advisor. The right one can reduce financial stress, streamline decision-making, and guide you to a wealthier future.",
      open: false,
    },
    {
      question: "Why is financial advisory so unpopular in India?",
      answer:
        "While awareness levels are currently low, we are seeing an improvement globally, including in India. Consumers are living longer in retirement than as part of the workforce. Regulators and governments are pulling away from guaranteed pensions, and employers are pulling away from offering lifetime employment. Consumers now need to take on the responsibility of creating wealth.",
      open: false,
    },
    {
      question:
        "Do you earn any commissions from the investment recommendation that you make?",
      answer: "No.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="faq-section" id="faq">
      <div className="faq-wrapper">
        <h2 className="faq-heading">FAQs</h2>
        <h3 className="faq-subtext">
          Let&#39;s answer those lingering questions
        </h3>
        <div className="faqs">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
