import React, { useState } from "react";
import { PopupModal } from "react-calendly";
const ComboPricing = () => {
  const bookTrialClicked = () => {
    setCalendlyOpen(true);
  };
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const ITRFilingCalendly =
    "https://calendly.com/bridgit-financial-advisors/packages";
  return (
    <>
      <div className="pricing-page-plans-intro">
        Our Combo Plans Provide a Dedicated CA and Financial Advisor to
        Seamlessly Manage Your Complete Financial Needs.
        <br></br>From Investment Portfolios to Tax Planning and ITR Filing,
        We've Got You Covered. Experience the Convenience of a One-Stop Shop for
        All Your Financial Requirements with Bridgit.
      </div>
      <div className="pricing-page-plans-checklist">
        <div className="pricing-page-plans-checklist-download">
          <div className="pricing-page-plans-checklist-download">
            Financial Planning + Tax Planning + ITR Filing = Your Path to Stress
            Free Financial Success
          </div>
        </div>
      </div>
      <div className="pricing-page-plans" id="combo-tab-click">
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container-combo">
            <h3 className="pricing-page-info-heading">
              Combo Exclusive Single
            </h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Income: (₹10L - ₹20L) p.a </li>
              <li>&bull; Dedicated CA for Tax Planning and ITR Filing</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Two 1:1 sessions with Financial Advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 3499
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="combo-pricing-exclusive-single"
              className="pricing-page-schedulepay-button-combo"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container-combo">
            <h3 className="pricing-page-info-heading">
              {" "}
              Combo Signature Single
            </h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Single Income: (₹20L - ₹35L) p.a </li>
              <li>&bull; Dedicated CA for Tax Planning and ITR Filing</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with Financial Advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 4999
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="combo-pricing-signature-single"
              className="pricing-page-schedulepay-button-combo"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container-combo">
            <h3 className="pricing-page-info-heading"> Combo Elite Family</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Family(of 2) Income: (₹15L - ₹25L) p.a </li>
              <li>&bull; Dedicated CA for Tax Planning and ITR Filing</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with Financial Advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 5499
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="combo-pricing-elite-family"
              className="pricing-page-schedulepay-button-combo"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container-combo">
            <h3 className="pricing-page-info-heading">Combo Family Select</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Family(of 2) Income: (₹25L - ₹35L) p.a </li>
              <li>&bull; Dedicated CA for Tax Planning and ITR Filing</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with Financial Advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">
                ₹ 6999
                <span className="pricing-page-price-span">
                  {" /year (incl. taxes)"}
                </span>
              </h4>
            </div>
            <button
              id="combo-pricing-elite-select"
              className="pricing-page-schedulepay-button-combo"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>
        <div className="pricing-page-plan-container">
          <div className="pricing-page-plan-info-container-combo">
            <h3 className="pricing-page-info-heading">Tailored</h3>
            <ul className="pricing-page-info-subtext">
              <li>&bull; Income: ₹35L+ p.a </li>
              <li>&bull; Dedicated CA for Tax Planning and ITR Filing</li>
              <li>&bull; Financial plan and dashboard</li>
              <li>&bull; Four 1:1 sessions with Financial Advisor</li>
              <li>&bull; Quarterly Reviews</li>
              <li>&bull; Whatsapp Hotline</li>
            </ul>
            <div className="pricing-page-price-container">
              <h4 className="pricing-page-price">Custom Pricing</h4>
            </div>
            <button
              id="combo-pricing-tailored"
              className="pricing-page-schedulepay-button-combo"
              onClick={() => bookTrialClicked()}
            >
              <div className="pricing-pricing-info-button-text">
                Get Started
              </div>
            </button>
          </div>
        </div>

        <PopupModal
          url={ITRFilingCalendly}
          //   pageSettings={this.props.pageSettings}
          //   utm={this.props.utm}
          //   prefill={this.props.prefill}
          onModalClose={() => setCalendlyOpen(false)}
          open={calendlyOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      </div>
    </>
  );
};

export default ComboPricing;
