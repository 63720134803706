const config = {
  development: {
    WEB_URL: "http://localhost:3000",
  },
  staging: {
    WEB_URL: "https://bridgit-website-staging.herokuapp.com/",
  },
  production: {
    WEB_URL: "https://bridgit.club/",
  },
};

export default {
  get: function (keyName, defaultValue) {
    if (config[keyName] !== undefined) {
      return config[keyName];
    }
    return defaultValue;
  },
};
