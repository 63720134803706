import React, { useState, useEffect } from "react";
import { PopupModal } from "react-calendly";
import { FaWhatsapp } from "react-icons/fa";
import "./pricing.css";
import config from "../../shared/config";

import { Mixpanel } from "../../mixpanel/mixpanel";
const PricingSection = () => {
  const onLinkClick = (url) => {
    window.open(url, "_self", "noopener,noreferrer");
  };
  const whatsappClicked = () => {
    openInNewTab("https://wa.me/919319096509");
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      let yOffset = 0;
      const windowWidth = anchorEl.getBoundingClientRect().width;
      if (windowWidth < 400) {
        yOffset = 1200;
      } else if (windowWidth > 400 && windowWidth < 500) {
        yOffset = 1410;
      } else if (windowWidth > 500 && windowWidth < 800) {
        yOffset = 1510;
      } else if (windowWidth > 800 && windowWidth < 1025) {
        yOffset = 900;
      } else if (windowWidth > 1025) {
        yOffset = 700;
      }
      const y = anchorEl.getBoundingClientRect().top + yOffset;

      if (anchorEl) {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div className="pricing-section" id="pricing">
      <div className="pricing-section-wrapper">
        <div className="pricing-container-left">
          <h2 className="pricing-heading">
            Our <span className="pricing-heading-span"> Pricing</span>
          </h2>
          <p className="pricing-subtext">
            Experience the Convenience of a One-Stop Shop for All Your Financial
            Requirements with Bridgit.{" "}
          </p>
          <h3 className="pricing-subtext2">Not convinced yet?</h3>
          <button className="pricing-button" onClick={() => whatsappClicked()}>
            <FaWhatsapp color="white" fontSize="1.5em"></FaWhatsapp>
            <div className="pricing-button-text"> Connect on Whatsapp</div>
          </button>
        </div>
        <div className="pricing-container-right">
          <div className="pricing-info-container">
            <h3 className="pricing-info-heading">Financial Planning Service</h3>
            <ul className="pricing-info-subtext">
              <li>&bull; Financial Plan & Dashboard</li>
              <li>&bull; Quarterly Portfolio Review</li>
              <li>&bull; Dedicated Financial Advisor</li>
              <li>&bull; Bridgit Whatsapp Hotline</li>
            </ul>
            <div className="pricing-info-divider"></div>
            <h4 className="pricing-info-price">
              Starting at ₹ 999
              <span className="pricing-info-price-span">/year</span>
            </h4>
            <button
              className="pricing-info-button"
              id="pricing-section-fps-button"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] + "/pricing"
                )
              }
            >
              <div className="pricing-info-button-text">Checkout plans</div>
            </button>
          </div>
          <div className="pricing-info-container">
            <h3 className="pricing-info-heading">Assisted Tax Filing</h3>
            <ul className="pricing-info-subtext">
              <li>&bull; Dedicated CA</li>
              <li>&bull; On Demand Tax Assistance</li>
              <li>&bull; Tax Calculation and Filing</li>
              <li>&bull; Bridgit Whatsapp Hotline</li>
            </ul>
            <div className="pricing-info-divider"></div>
            <h4 className="pricing-info-price">Starting at ₹ 799/-</h4>
            <button
              className="pricing-info-button"
              id="pricing-section-itr-button"
              onClick={() =>
                onLinkClick(
                  config.get(process.env.NODE_ENV)["WEB_URL"] +
                    "/pricing/taxfiling"
                )
              }
            >
              <div className="pricing-info-button-text">Checkout plans</div>
            </button>
          </div>
          <div className="pricing-subtext-mobile">Not convinced yet?</div>
          <button
            className="pricing-button-mobile"
            id="pricing-section-wa-button"
            onClick={() => whatsappClicked()}
          >
            <FaWhatsapp color="white" fontSize="1.5em"></FaWhatsapp>
            <div id="pricing-section-whatsapp" className="pricing-button-text">
              Connect on Whatsapp
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
