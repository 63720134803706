import { PopupModal } from "react-calendly";
import React, { useState } from "react";
import "./whysection.css";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Stack } from "react-bootstrap";

const WhySection = () => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);

  return (
    <div className="section-3-why-section">
      <div className="why-section-wrapper">
        <div className="overlap-group1-why-section">
          <img
            className="bg-asset-why-section"
            src={require("../../images/question-mark.svg").default}
            alt="bridgit-financial-planning"
          />
          <button
            className="cta-primary-why-section"
            id="why-section-book-button"
            onClick={() => setCalendlyOpen(true)}
          >
            <div className="cta-text-why-section">Book a FREE trial</div>
          </button>
          <PopupModal
            url="https://calendly.com/bridgit-financial-advisors/financial-planning"
            //   pageSettings={this.props.pageSettings}
            //   utm={this.props.utm}
            //   prefill={this.props.prefill}
            onModalClose={() => setCalendlyOpen(false)}
            open={calendlyOpen}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
          />
          <h2 className="heading-why-section">
            Why you need a Financial Plan?
          </h2>
        </div>
        <div className="flex-col-why-section">
          <Carousel controls={false} interval={8000} touch={true}>
            <Carousel.Item>
              <div className="card-container-why-section">
                <h3 className="card-heading-why-section">
                  1. To know whats right
                </h3>
                <p className="card-text-why-section">
                  There are 1000s of investment options out there. Selecting the
                  right option requires skill and knowledge.
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-container-why-section">
                <h3 className="card-heading-why-section">2. Be on track</h3>
                <p className="card-text-why-section">
                  With our ever evolving financial needs and changing market
                  conditions we need someone to make sure our goals are always
                  on track.
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-container-why-section">
                <h3 className="card-heading-why-section">
                  3. For better returns!
                </h3>
                <p className="card-text-why-section">
                  People with a written financial plan are two and a half times
                  more likely to save enough money for retirement.
                </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="card-container-why-section">
                <h3 className="card-heading-why-section">4. To build wealth</h3>
                <p className="card-text-why-section">
                  Time is money, and there's a cost to delaying good financial
                  decisions or prolonging poor ones, like keeping too much cash
                  or putting off doing an investment plan.
                </p>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default WhySection;
