import React, { useState } from "react";
import StatSection from "../components/StatSection/statSection";
import HeroSection from "../components/HeroSection/index";
import WhySection from "../components/WhySection";
import PlanForEverything from "../components/PlanForEverythingSection";
import HowWeBridgeSection from "../components/HowWeBridge";
import {
  getStarted,
  quarterlyReview,
  advisorySession,
  personalizedHotline,
} from "../components/HowWeBridge/Data";
import PricingSection from "../components/PricingSection";
import HappyStories from "../components/HappyStoriesSection";
import FAQSection from "../components/FaqSection";
import NavBar from "../components/NavBar";
import FooterSection from "../components/Footer";
import { Routes, Route } from "react-router-dom";
import TermsOfService from "../components/T&C";
import Banner from "../components/Banner";
const HomePage = () => {
  return (
    <>
      <NavBar />
      <Banner />
      <HeroSection />
      <StatSection />
      <PlanForEverything />
      <HowWeBridgeSection {...getStarted} />
      <HowWeBridgeSection {...quarterlyReview} />
      <HowWeBridgeSection {...advisorySession} />
      <HowWeBridgeSection {...personalizedHotline} />
      <HappyStories />
      <PricingSection />
      <WhySection />
      <FAQSection />
      <FooterSection />
    </>
  );
};

export default HomePage;
